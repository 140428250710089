import React from 'react';
import './PropertyDetails.scss';
import CommonHeading from '../../common/CommonHeading/CommonHeading';
import { Container } from 'react-bootstrap';
import MetricsCard from './MetricsCard/MetricsCard';
import { useParams } from 'react-router-dom';
const PropertyDetails = () => {
  const { id } = useParams();
  return (
    <>
      <div className="propertyDetailsPage">
        <Container>
          <CommonHeading heading="Property details" />
          <MetricsCard id={id} />
        </Container>
      </div>
    </>
  );
};

export default PropertyDetails;
