
import React, { useEffect, useState, useRef } from "react";
import "./Chats.scss";
import ChatsUserList from "./ChatsUserList/ChatsUserList";
import ChatsGroupList from "./ChatsGroupList/ChatsGroupList";
import { http } from "../../../utils/http";
import Loader from "../../common/Loader/Loader";
import {
  ArrowRightgreyIcon,
  EyeOpen,
  MenuIcon,
  SearchIcon,
  ClearIcon,
  AddTagIcon,
} from "../../../assets/svgs/svg";
import menuArrow from "../../../assets/svgs/menuArrow.svg";
import { useParams, useSearchParams } from "react-router-dom";
import CommonButton from "../../common/CommonButton/CommonButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Profile from "../profile/Profile";
import { useNavigate } from 'react-router-dom';
import { isEmpty } from "lodash";
import SearchBox from "../../common/SearchBox/searchBox";

const Chats = ({ }) => {
  const [list, setList] = useState([]);
  const [property, setProperty] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [message, setMessage] = useState("");
  const [chatList, setChatList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState("");
  const [recieverUser, setRecieverUser] = useState("");
  const [selectedList, setSelectedList] = useState(null);
  const [unread, setUnread] = useState(0);
  const [unreadMsg, setUnreadMsg] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [isLeftSideOpen, setIsLeftSideOpen] = useState(true);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [isActive, setIsActive] = useState(false);
  const [unreadList, setUnreadList] = useState(0);
  const [selected, setIsSelected] = useState("Select Property");
  const dropdownRef = useRef(null);
  const [users, setUsers] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [timezone, setTimezone] = useState('');
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    getChatlist();
    getProfile();
    http.get("/messages").then((res) => {
      if (res?.status === 200) {
        setUnreadMsg(res?.data);
      }
    });
  }, []);

  const fetchProperties = async () => {
    const response = await fetch('/api/properties');
    const data = await response.json();
    setProperty(data);
    selectInitialProperty(data);
  };

  const selectInitialProperty = (properties) => {
    const sortedProperties = properties.sort((a, b) => b.unread - a.unread);
    setIsSelected(sortedProperties[0]);
    loadChats(sortedProperties[0].id);
  };

  const loadChats = async (propertyId) => {
    navigate(`/chats/${propertyId}`);
  };

  const getProfile = () => {
    try {
      http.get("/profile").then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          setUser(res?.data);
          // console.log("user:", user)
          setTimezone(res?.data.timezone);
        }
      });
    } catch (error) {
      console.error("error", error);
    }
  };

  const updateMessageList = (newMessage) => {
    setMessageList([...messageList, newMessage]);
  };

  const setChatListData = (id) => {
    if (id) {
      setSelectedList(null);
      setMessageList([]);
      setProperty(id);
      const result = list?.filter((item) => item.id === id);
      setChatList(result[0]?.tenants || []);
      setUnread(result[0]?.unread);
    }
  };

  // const getChatlistAll = (id) => {
  //   let propertyId = searchParams.get("id");
  //   try {
  //     if (id || propertyId) {
  //       http.get(`/messages/${id ? id : propertyId}`).then((res) => {
  //         if (res?.status === 200) {
  //           let tenants = res?.data?.tenants || [];
  //           // 先按照是否置顶（pin字段）进行排序，置顶的排在前面
  //           tenants.sort((a, b) => {
  //             // 假设pin为true表示需要置顶
  //             const pinA = a.tags?.pin || false;
  //             const pinB = b.tags?.pin || false;
  //             // 置顶的排在前面，非置顶的排在后面
  //             return pinB - pinA;
  //           });
  //           // 接着按lastActive降序排列非置顶的项
  //           tenants = tenants.filter(tenant => !tenant.tags?.pin)
  //             .sort((a, b) => new Date(b.lastActive) - new Date(a.lastActive));
  //           // 接着按未读降序排列非置顶的项
  //           tenants = tenants.slice().sort((a, b) => b.unread - a.unread);
  //           // 如果有置顶的消息，再次确保它们排在最前面
  //           const pinnedTenants = tenants.filter(tenant => tenant.tags?.pin);
  //           const nonPinnedTenants = tenants.filter(tenant => !tenant.tags?.pin);
  //           tenants = [...pinnedTenants, ...nonPinnedTenants];
  //           if (searchParams.get("id") || id) {
  //             setChatList(tenants);
  //             setUnread(res?.data?.unread);
  //           } else if (id) {
  //             setChatList(tenants);
  //             setUnread(res?.data?.unread);
  //           }
  //           setTenants(tenants);
  //         }
  //       });
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     console.error(error);
  //   }
  // };

  const getChatlistAll = (id) => {
    let propertyId = searchParams.get("id");
    try {
      if (id || propertyId) {
        http.get(`/messages/${id ? id : propertyId}`).then((res) => {
          if (res?.status === 200) {
            let tenants = res?.data?.tenants || [];

            // Separate pinned tenants
            const pinnedTenants = tenants.filter(tenant => tenant.tags?.includes('pinned'));
            const otherTenants = tenants.filter(tenant => !tenant.tags?.includes('pinned'));

            // Sort other tenants by unread count and lastActive
            otherTenants.sort((a, b) => {
              // First, sort by unread count in descending order
              if (b.unread !== a.unread) {
                return b.unread - a.unread;
              }
              // Then, sort by lastActive in descending order
              return new Date(b.lastActive) - new Date(a.lastActive);
            });

            // Combine pinned and sorted other tenants
            tenants = [...pinnedTenants, ...otherTenants];

            if (id || propertyId) {
              setChatList(tenants);
              setUnread(res?.data?.unread);
            }

            setTenants(tenants);
          }
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getChatlistAll();
  }, []);

  const getChatlist = () => {
    try {
      http.get("/properties").then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          setList(res?.data);
          if (searchParams.get("id")) {
            let propertyId = searchParams.get("id");
            setProperty(propertyId);
            setSelectedList(null);
            setMessageList([]);
            setProperty(propertyId);
          }
        }
      });
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const getChats = (userId, rid = false) => {
    setMessage("");
    if (rid) {
      setLoading(true);
    }
    try {
      http.get(`/messages/${property}/${userId}`).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          setMessageList(res?.data);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const sendMessageApi = async (id, imageList, tenantId) => {
    if (!message && !imageList) {
      return;
    } else {
      let urlJoin = undefined;
      if (imageList && typeof imageList.length) {
        let promiseImgList = imageList.map(async (v) => {
          let fromDate = new FormData();
          fromDate.set("attachment", v);
          let res = await http.post(
            `http://assets.hexon.ai/upload/${tenantId}/attachment`,
            fromDate
          );
          return `${res.data}`;
        })
        let urlList = await Promise.all(promiseImgList)
        urlJoin = urlList.join(',')
      }

      const list = [...messageList];

      let body = {
        to: recieverUser?.id,
        content: message || null,
        attachment: urlJoin || null,
      };
      list.push(body);
      setMessageList(list);
      try {
        if (id != null) {
          http.post(`/message/${id}`, body).then((res) => {
            if (res?.status === 200) {
              setMessage("");
            }
          });
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }
  };

  const updateWindowDimensions = () => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWindowDimensions);
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  function findAddress(addressToFind, dataArray) {
    for (let i = 0; i < dataArray.length; i++) {
      if ((dataArray[i].address === addressToFind)) {
        if (dataArray[i].unread > 0) {
          return dataArray[i];
        }
      }
    }
    return null;
  }

  const unreadMsgCount = (unreadList) => {
    let totalUnread = 0;
    unreadList.forEach((item) => {
      totalUnread += item.unread;
    });
    return totalUnread;
  };

  const handleDropdownToggle = () => {
    setIsActive(!isActive);
  };

  const handleMenuToggle = () => {
    screenWidth <= 767 && setIsLeftSideOpen((prevIsOpen) => !prevIsOpen);
  };

  // const handleSearchChange = (e) => {
  //   setSearchTerm(e.target.value);
  // };

  const handleConfirmSearch = () => {
    // Handle the search confirmation logic here
    console.log("Search confirmed with term:", searchTerm);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const togglePinProperty = (id) => {
    setChatList(prevChatList =>
      prevChatList.map(chat =>
        chat.id === id
          ? { ...chat, tags: chat.tags.includes('pinned') ? chat.tags.filter(tag => tag !== 'pinned') : [...chat.tags, 'pinned'] }
          : chat
      )
    );
  };


  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="Chats">
          <div className={`Chats_leftSide ${isLeftSideOpen ? "open" : "closed"}`}>
            <div className="slectRead" ref={dropdownRef}>
              {list && list.length > 0 && (
                <div className="dropdown property-dropdown">
                  <div onClick={handleDropdownToggle} className="dropdown-btn property-dropdown-btn">
                    {selected || "Select Property"}
                    <span className={isActive ? "fas fa-caret-up" : "fas fa-caret-down"} />
                  </div>
                  <div className="dropdown-content property-dropdown-content" style={{ display: isActive ? "block" : "none" }}>
                    {list
                      .filter((item) => item.address.line1)
                      .map((data) => (
                        <div
                          className="item"
                          onClick={() => {
                            setChatListData(data._id);
                            getChatlistAll(data._id);
                            setIsActive(false);
                            setIsSelected(data.address.line1);
                          }}
                          key={data._id}
                          value={data._id}
                        >
                          {findAddress(data.address.line1, unreadMsg) ? <span className="unread-dot"></span> : ""}
                          &nbsp;
                          {data.address.line1}
                        </div>
                      ))}
                  </div>
                </div>
              )}

              {unreadMsg.length > 0 && (
                <button
                  style={{
                    width: "auto",
                    height: "auto",
                    backgroundColor: "red",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    margin: "10px",
                    padding: "0 10px",
                  }}
                >
                  {unreadMsgCount(unreadMsg)}
                </button>
              )}
            </div>
            {selected !== "Select Property" && (
              <SearchBox
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                onSearch={handleConfirmSearch}
                onClear={handleClearSearch}
              />

            )}
            <ChatsUserList
              timezone={timezone}
              chatList={chatList}
              getChats={getChats}
              setRecieverUser={setRecieverUser}
              recieverUser={recieverUser}
              setSelectedList={setSelectedList}
              selectedList={selectedList}
              handleMenuToggle={handleMenuToggle}
              searchTerm={searchTerm}
              getChatlistAll={getChatlistAll}
              id={property}
              onPin={togglePinProperty}
            />
          </div>
          <div className="Chats_rightSide">
            <ChatsGroupList
              timezone={timezone}
              id={property}
              chatList={chatList}
              recieverUser={recieverUser}
              handleMenuToggle={handleMenuToggle}
              sendMessageApi={sendMessageApi}
              setMessage={setMessage}
              updateMessageList={updateMessageList}
              isLeftSideOpen={isLeftSideOpen}
              messageList={messageList}
              user={user}
              selectedList={selectedList}
              message={message}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Chats;

