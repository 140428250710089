import React, { useState, useEffect } from "react";
import "./AddStep4.scss";
import { Col, Row } from "react-bootstrap";
import CheckBox from "../../../common/formik/CheckBox/CheckBox";
import CommonButton from "../../../common/CommonButton/CommonButton";
import { toast } from "react-hot-toast";
import ImageDrag from "./ImageDrag";
import { useLocation } from "react-router-dom";
import { http } from "../../../../utils/http";
import Loader from "../../../common/Loader/Loader";
import { PictureIcon, VideoIcon } from "../../../../assets/svgs/svg";

const AddStep4 = (props) => {
	const { setCurrentStep, setDetail, detail, addProperty, previewImages, setPreviewImage, social, setSocial, setIsBlocker, setIsChange, blocker, buttonClicked } = props;
	const [loading, setLoading] = useState(false);
	setPreviewImage(detail?.photos)
	setSocial(detail?.platforms)
	const [images, setImages] = useState(detail?.photos || null);
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search);
	const propertyId = searchParams.get('id');

	const onChangeHandler = (e) => {
		setIsChange(true);
		const { id } = e.target;
		if (e.target.checked) {
			setSocial({ ...social, [id]: true });
		} else {
			setSocial({ ...social, [id]: false });
		}
	};

	const handleSubmit = () => {
		if ((!previewImages) || (previewImages && previewImages.length === 0)) {
			toast.error("please select at least one image !!");
			blocker.reset()
		} else if (social.facebook || social.craigslist || social.zumper) {
			//update status to active
			if (buttonClicked === "save") {
				addProperty();
			} else {
				detail.status = "active";
				addProperty();
			}
		} else {
			toast.error("please select at least one Post option !!");
			blocker.reset()
		}
	};

	const handleUploadSubmit = async (file) => {
		setLoading(true);
		if (file) {
			const formData = new FormData();
			formData.append('photos', file);
			try {
				const response = await http.post('http://api.hexon.ai/upload/' + propertyId + '/photo', formData);
				if (response.status === 200) {
					setPreviewImage([...previewImages, response?.data[0]]);
					setDetail((old) => ({ ...old, ["photos"]: [...old.photos, response?.data[0]] }));
					// setDetail({ ...detail, ["photos"]: [...detail.photos, response?.data[0]] });
					// console.log('File uploaded successfully.');
					setLoading(false);
				} else {
					setLoading(false);
					console.error('Failed to upload file.');
				}
			} catch (error) {
				setLoading(false);
				console.error('Error uploading file:', error);
			}
		} else {
			setLoading(false);
			console.log('No file selected.');
		}
	};

	const handleImageChange = async (event) => {
		setIsChange(true);
		const selectedImages = event.target.files;
		if (selectedImages[0]?.name.endsWith('.webp')) {
			toast.error(".webp file type is not allowed !!");
			return;
		}
		const temp = [];
		const preview = [];
		for (let i = 0; i < selectedImages.length; i++) {
			const file = selectedImages[i];
			// preview.push(URL.createObjectURL(file));
			preview.push(file);
			handleUploadSubmit(file)

		}
		setPreviewImage([...previewImages, ...preview]);
		setDetail({ ...detail, ["photos"]: [...detail.photos, ...temp] });
		setImages(temp);
	};

	const imageDeleteHandler = (index) => {
		props.setIsChange(true);
		const list = [...previewImages];
		const new_img = [...images];
		list.splice(index, 1);
		new_img.splice(index, 1);
		setPreviewImage(list);
		setDetail({ ...detail, ["photos"]: list });
	};

	return (
		<>
			{
				loading && <Loader
				/>
			}
			<div className="uploadProperty">
				<p className="propertyHeading">Property Details</p>
				<form className="mt-4">
					<Row>
						<Col xs={12}>
							<label for="" className="form-label">
								Upload Files
							</label>
							<div className="d-flex">
								<>
									{
										previewImages?.length > 0 &&
										<div className="uploadingPictures">
											<ImageDrag
												setDetail={setDetail}
												detail={detail}
												previewImages={previewImages} setPreviewImage={setPreviewImage}
												imageDeleteHandler={imageDeleteHandler}
												handleImageChange={(e) => {
													handleImageChange(e)
												}}
											/>
											<div className="m-1"></div>
										</div>
										||
										<div className="uploadFile text-center">
											<span className="uploadIcon">
												<PictureIcon />
											</span>
											{previewImages?.length > 0 ? (
												<p>Add more photos</p>
											) : (
												<p>Upload Photo of Your Property</p>
											)}
											<input
												type="file"
												multiple
												accept="image/*"
												className="uploadinput"
												onChange={(e) => {
													handleImageChange(e);
													props.setIsChange(true);
												}}
											// accept=".png, .gif, .jpeg, .svg .jpg .bmp"
											/>
										</div>
									}

								</>
							</div>
						</Col>
						<Col xs={12} className="mt-5">
							<label for="" className="form-label">
								Post on
							</label>
							<CheckBox
								label="Facebook"
								onChange={(e) => {
									onChangeHandler(e);
									setDetail({
										...detail,
										["platforms"]: {
											facebook: e.target.checked,
											craigslist: social.craigslist,
											zumper: social.zumper,
										},
									});
								}}
								id="facebook"
								checked={social?.facebook}
							/>
							<CheckBox
								label="Craigslist"
								onChange={(e) => {
									onChangeHandler(e);
									setDetail({
										...detail,
										["platforms"]: {
											facebook: social.facebook,
											craigslist: e.target.checked,
											zumper: social.zumper,
										},
									});
								}}
								id="craigslist"
								checked={social?.craigslist}
							/>
							{/* <CheckBox
                label="Zumper"
                onChange={(e) => {
                  onChangeHandler(e);
                  setDetail({
                    ...detail,
                    ["platforms"]: {
                      facebook: social.facebook,
                      craigslist: social.craigslist,
                      zumper: e.target.checked,
                    },
                  });
                }}
                id="zumper"
                checked={social?.zumper}
              /> */}
						</Col>
					</Row>
					<div className="button_margin">
						<div className="d-flex align-items-center justify-content-between mb-5">
							<CommonButton
								text="Back"
								className="grey-btn"
								onClick={() => setCurrentStep(3)}
							/>
							<CommonButton
								text="Submit"
								type="button"
								id="addStepSubmit"
								onClick={() => {
									setIsBlocker(false);

									handleSubmit();
								}}
							/>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default AddStep4;
