import React, { useState } from "react";
import OTPInput, { ResendOTP } from "otp-input-react";
import "./OtpCode.scss";

const OtpCode = (props) => {
  const{ OTPLength, resendClick ,setOtp,otp}=props
  // const renderButton = (buttonProps) => {
  //   return <button {...buttonProps}>Resend</button>;
  // };

  return (
    <>
      <div className='otp_input_box'>
        <OTPInput
          className='otp_input'
          value={otp}
          onChange={setOtp}
          autoFocus
          OTPLength={4}
          otpType="number"
          disabled={false}
          inputType="text"
          secure={false}
        />
        {/* <div className="d-flex align-items-center">
          <p>Didn’t receive code?</p>
          <ResendOTP
            maxTime={60}
            className="otp_resend"
            renderButton={renderButton}
            onResendClick={() => resendClick()}
          />
          <ResendOTP className="ResendOTP" onResendClick={() => console.log("Resend clicked")} />
        </div> */}
      </div>
    </>
  );
};

export default OtpCode;
