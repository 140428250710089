import React from 'react';
import Modal from 'react-bootstrap/Modal';
import CommonButton from '../CommonButton/CommonButton';
import './CustomModal.scss';

const CustomModal = ({ show, handleCancel, handleClose, handleSave, message, saveButton }) => {
  return (
    <Modal show={show}>
      <Modal.Header closeButton onHide={handleClose} centered>
        <Modal.Title>
          <h3 className="modal-title">Hexon AI remind you:</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        {message}
      </Modal.Body>
      <Modal.Footer>
        <div className="modal-footer-buttons">
          <CommonButton
            text="Cancel"
            type="button"
            className="grey-btn"
            onClick={handleCancel}
          />
          <CommonButton
            text={saveButton}
            type="button"
            onClick={handleSave}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
