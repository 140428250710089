import React, { useContext, useEffect, useState } from "react";
import "./Profile.scss";
import { Col, Row } from "react-bootstrap";
import FormikControl from "../../common/formik/FormikControl";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import CommonButton from "../../common/CommonButton/CommonButton";
import { http } from "../../../utils/http";
import { emailRegex } from "../../../utils/helper";
import Loader from "../../common/Loader/Loader";
import { UserContext } from "../../../App";
import dummyProfilePic from "../../../assets/images/dummyProfilePic.webp";
import { appUrl, assets, avatarPath, userPath } from "../../../utils/config";
import { useStore } from "react-redux";
import Select from "../../common/formik/Select/Select";

const Profile = () => {
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState(null);
  const [filePath, setFilePath] = useState('');
  const [image, setImage] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [newLaod, setNewLoad] = useState(false);
  const [currentEmail, setCurrentEmail] = useState(null);


  useEffect(() => {
    setFilePath(appUrl + '/' + avatarPath + '/' + userPath + '/' + imagePreview)
  }, [imagePreview]);

  const initialValues = {
    fname: "",
    lname: "",
    email: "",
    timezone: "",
  };
  const initialValues1 = {
    old_password: "",
    password: "",
    confirm_password: "",
  };

  const { updateUserProfile } = useContext(UserContext);

  const getUserDetail = (formik) => {
    try {
      if (!newLaod) {
        setNewLoad(true);
        http.get("/profile").then((res) => {
          setLoading(false);
          if (res?.status === 200) {
            const { fname, lname, email, timezone } = res?.data;
            console.log(res.data)
            formik.setValues({
              fname,
              lname,
              email,
              timezone: timezone || '',
            });
            console.log(timezone)
            setCurrentEmail(email);
            setImagePreview(res?.data?.avatar);
            setImage(res?.data?.avatar);
          }
        });
      }
    } catch (error) {
      console.error("error", error);
    }

  };
  //初始化表单
  const validationSchema = Yup.object({
    fname: Yup.string().required("This field is required"),
    lname: Yup.string().required("This field is required"),
    email: Yup.string()
      .matches(emailRegex, "Invalid email")
      .required("This field is required"),
    timezone: Yup.string().required('Required').notOneOf([''], 'Please select a time zone')
  });

  const validationSchema1 = Yup.object({
    old_password: Yup.string().required("This field is required"),
    password: Yup.string()
      .required("This field is required")
      .notOneOf(
        [Yup.ref("old_password"), null],
        "password should not be same as new password"
      ),
    confirm_password: Yup.string()
      .required("This field is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const handleSubmit = (values, submitProps, isPassword) => {
    if (!isPassword) {
      let body = {
        email: values?.email,
        fname: values?.fname,
        lname: values?.lname,
        timezone: values?.timezone,
        avatar: image,
      };
      try {
        setLoading(true);
        http.post("/profile", body).then((res) => {
          setLoading(false);
          submitProps.setSubmitting(false);
          if (res?.status === 200) {
            toast.success("profile updated successfully");
            if (currentEmail != body.email) {
              localStorage.clear();
              navigate("/login");
            } else {
              updateUserProfile({ imageUrl: image });
              navigate("/dashboard");
            }
          }
        });
      } catch (error) {
        setLoading(false);
        submitProps.setSubmitting(false);
        console.error("error", error);
      }
    } else {
      let body = {
        password: values.password,
        oldPassword: values.old_password,
      };
      try {
        setLoading(true);
        http.post("/profile", body).then((res) => {
          setLoading(false);
          submitProps.setSubmitting(false);
          if (res?.status === 200) {
            toast.success("profile updated successfully");
            localStorage.clear();
            navigate("/login");
          }
        });
      } catch (error) {
        setLoading(false);
        submitProps.setSubmitting(false);
        console.error("error", error);
      }
    }
  };

  // Function to handle file input change
  const handleImageChange = async (event) => {
    try {
      const file = event.target.files[0];
      if (file.name.endsWith('.webp')) {
        toast.error(".webp file type is not allowed !!");
        return;
      }
      setImage(file)
      const formData = new FormData();
      formData.append('avatar', file);
      const response = await http.post('http://api.hexon.ai/upload/avatar', formData);
      setIsEditing(!isEditing);
      setImagePreview(response?.data);
      setFilePath(appUrl + '/' + avatarPath + '/' + userPath + '/' + response?.data);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  // Function to toggle the editing state
  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  // 下拉列表的选项 - timeZone -对应不同的城市名
  const dropdownOptions = [
    { key: 'please choose a time zone', value: '' },
    { key: 'Halifax/Saint John (AST) - UTC-4', value: 'America/Halifax' },
    { key: 'Toronto/Montreal (EST) - UTC-5', value: 'America/Toronto' },
    { key: 'Winnipeg/Saskatoon (CST) - UTC-6', value: 'America/Winnipeg' },
    { key: 'Calgary/Edmonton (MST) - UTC-7', value: 'America/Edmonton' },
    { key: 'Vancouver/Victoria (PST) - UTC-8', value: 'America/Vancouver' }
  ];

  return (
    <>
      <div className="common_login_screens">
        <div className="px-0 login_Col">
          <div className="login_box">
            <div className="login_box_heading">
              <h2>Profile Detail</h2>
            </div>
            <div className={"d-block"}>
              <div className="profile-image-uploader">
                <div className="image-preview" onClick={toggleEdit}>
                  {imagePreview ? (
                    <img src={filePath} alt="Profile" />
                  ) : (
                    <div
                      className={`placeholder ${isEditing ? "editing" : ""}`}
                    >
                      <img src={dummyProfilePic} alt="Profile" />
                    </div>
                  )}
                </div>
                {isEditing && (
                  <div className="file-input-container">
                    <input
                      type="file"
                      id="profile-image-input"
                      accept="image/*"
                      onChange={handleImageChange}
                      className="file-input"
                    />
                    <label htmlFor="profile-image-input" className="file-label">
                      <i className="fa fa-cloud-upload"></i> Choose Image
                    </label>
                  </div>
                )}
              </div>
              {
                <Formik
                  validationSchema={validationSchema}
                  initialValues={initialValues}
                  onSubmit={(values, submitProps) => {
                    handleSubmit(values, submitProps, false);
                  }}
                  enableReinitialize
                >
                  {(formik) => (
                    <Form>
                      {getUserDetail(formik)}
                      <Row>
                        <Col xs={6} md={6}>
                          <FormikControl
                            type="text"
                            placeholder="First Name"
                            name="fname"
                            innerClass="iconBoxinputnew"
                            value={formik.values.fname}
                          />
                        </Col>
                        <Col xs={6} md={6}>
                          <FormikControl
                            type="text"
                            placeholder="Last Name"
                            name="lname"
                            innerClass="iconBoxinputnew"
                            value={formik?.values.lname}
                          />
                        </Col>
                        <Col xs={12}>
                          <FormikControl
                            type="email"
                            placeholder="Enter your email"
                            name="email"
                            innerClass={`iconBoxinput`}
                            lefticonshow={true}
                            righticonshow={formik?.values?.email}
                            value={formik?.values.email}
                            autoComplete="false"
                          />
                        </Col>
                        <Col xs={12}>
                          <FormikControl
                            // control="select"
                            control="select"
                            type="timezone"
                            placeholder="please select a time zone"
                            name="timezone"
                            // 当时区选择发生变化时调用此函数

                            handleChange={(e) => {
                              //test 
                              console.log('test' + e)
                              formik.setFieldValue("timezone", e.target.value); // 更新Formik的状态 

                            }
                            }
                            onBlur={formik.handleBlur} // 确保触发校验
                            error={formik.touched.timezone && formik.errors.timezone}
                            // innerClass={`iconBoxinput`}
                            options={dropdownOptions}
                            value={formik.values.timezone}
                          />
                        </Col>
                        <Col xs={12}>
                          <CommonButton
                            isLoading={formik?.isSubmitting}
                            type="submit"
                            text="Continue"
                            fluid={true}
                            disabled={!formik.isValid}
                          />
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              }
              {/* password section */}
              <div className="mb-4"></div>
              {
                <Formik
                  validationSchema={validationSchema1}
                  initialValues={initialValues1}
                  onSubmit={(values, submitProps) => {
                    handleSubmit(values, submitProps, true);
                  }}
                  enableReinitialize
                >
                  {(formik) => (
                    <Form>
                      <Row>
                        <Col xs={12}>
                          <div className="iconBoxinput">
                            <FormikControl
                              control="password"
                              name="old_password"
                              placeholder="old password"
                              className="mb-4"
                              lefticonshow={true}
                              innerClass="iconBoxinput"
                              autoComplete="false"
                            />
                          </div>
                        </Col>

                        <Col xs={12}>
                          <div className="iconBoxinput">
                            <FormikControl
                              control="password"
                              name="password"
                              placeholder="new password"
                              className="mb-4"
                              lefticonshow={true}
                              innerClass="iconBoxinput"
                              autoComplete="false"
                            />
                          </div>
                        </Col>
                        <Col xs={12}>
                          <div className="iconBoxinput">
                            <FormikControl
                              control="password"
                              name="confirm_password"
                              placeholder="confirm Password"
                              className="mb-4"
                              lefticonshow={true}
                              innerClass="iconBoxinput"
                              autoComplete="false"
                            />
                          </div>
                        </Col>
                        <Col xs={12}>
                          <CommonButton
                            isLoading={formik?.isSubmitting}
                            type="submit"
                            text="Continue"
                            fluid={true}
                            disabled={!formik.isValid}
                          />
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              }
            </div>
          </div>
        </div>
      </div>
      {loading ? <Loader /> : <></>}
    </>
  );
};

export default Profile;