import Errormsg from '../../../common/formik/ErrorMsg/Errormsg'
import React, { useState } from 'react';
import "./AddStep1.scss";
import { Form, Formik } from 'formik';
import { Col, Row } from 'react-bootstrap';
import FormikControl from '../../../common/formik/FormikControl';
import * as Yup from 'yup';
import CommonButton from '../../../common/CommonButton/CommonButton';
import Select from 'react-select';
import { useNavigate, useSearchParams } from "react-router-dom";
import { http } from '../../../../utils/http';
import _ from 'lodash';

const canadianPostalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;

const AddStep1 = (props) => {
  const navigate = useNavigate();
  const { setCurrentStep, setDetail, detail, setAdd, setPatchState, buttonClicked } = props;
  const [clicked, setClicked] = useState();
  const [selectedType, setSelectedType] = useState({ label: detail?.type, value: detail?.type });
  const [selectedProvince, setSelectedProvince] = useState({ label: detail?.address?.province, value: detail?.address?.province });
  const [searchParams] = useSearchParams();
  const propertyId = searchParams.get("id");

  const propType = [
    { label: "Apartment", value: "Apartment" },
    { label: "House", value: "House" },
    { label: "Townhouse", value: "Townhouse" },
  ];

  const province = [
    { label: "Alberta", value: "Alberta" },
    { label: "British Columbia", value: "British Columbia" },
    { label: "Manitoba", value: "Manitoba" },
    { label: "New Brunswick", value: "New Brunswick" },
    { label: "Newfoundland and Labrador", value: "Newfoundland and Labrador" },
    { label: "Nova Scotia", value: "Nova Scotia" },
    { label: "Ontario", value: "Ontario" },
    { label: "Prince Edward Island", value: "Prince Edward Island" },
    { label: "Quebec", value: "Quebec" },
    { label: "Saskatchewan", value: "Saskatchewan" },
  ];

  const validationSchema = Yup.object({
    line1: Yup.string().required('This line1 is required'),
    city: Yup.string().required('This city is required'),
    province: Yup.string().required('This province is required'),
    zip: Yup.string().required('This zip is required').matches(canadianPostalCodeRegex, "Invalid"),
    type: Yup.string().required('This type is required'),
    bedroom: Yup.number().required('This bedroom is required').test(
      'Is positive?',
      'The number must be greater than 0!',
      (value) => value > 0
    ),
    bathroom: Yup.number().required('This bathroom is required').test(
      'Is positive?',
      'The number must be greater than 0!',
      (value) => value > 0
    ),
    sqft: Yup.number().required('This sqft is required').test(
      'Is positive?',
      'The number must be greater than 0!',
      (value) => value > 0
    ),
    rent: Yup.number().required('This rent is required').test(
      'Is positive?',
      'The number must be greater than 0!',
      (value) => value > 0
    ),
    availability: Yup.string().required('This availability is required'),
  });

  const initialValues = {
    line1: detail?.address?.line1,
    line2: detail?.address?.line2,
    city: detail?.address?.city,
    province: detail?.address?.province,
    zip: detail?.address?.zip,
    bedroom: detail?.bedroom,
    bathroom: detail?.bathroom,
    sqft: detail?.sqft,
    rent: detail?.rent,
    availability: detail?.availability,
    type: detail?.type
  };

  const handleSubmit = (values) => {
    const newAddress = {
      line1: values?.line1,
      line2: values?.line2,
      city: values?.city,
      province: values?.province,
      zip: values?.zip,
    };

    let data = {
      address: newAddress,
      bedroom: values?.bedroom,
      bathroom: values?.bathroom,
      sqft: values?.sqft,
      rent: values?.rent,
      availability: values?.availability,
      type: values?.type
    };

    setDetail({ ...detail, ...data });
    if (!propertyId) {
      addProperty(data); // Pass the data object to addProperty
    } else {
      if (!_.isEqual(initialValues, values)) {
        updateProperty(data, propertyId);
      } else {
        console.log("initialValues: ", initialValues)
        console.log("values: ", values)
        setCurrentStep(2);
        console.log("No changes detected, no update required. ")
      }
    }
  };

  const addProperty = (data) => {
    try {
      data.facebook = 'null';
      data.platforms = "null";
      http.post("/property", data).then((res) => {
        console.log("detail", res?.data);
        console.log("id: ", res?.data?._id)
        if (res?.data) {
          setDetail(old => ({ ...old, ...data }));
          if (buttonClicked === "save") {
            navigate("/dashboard");
          } else {
            navigate(`/dashboard/property?id=${res?.data?._id}&type=draft`);
            setCurrentStep(2);
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const updateProperty = (data, id) => {
    try {
      console.log(detail.platforms);
      // 发送 PATCH 请求，直接传递 data 和 platforms
      http.patch(`/property/${id}`, {
        ...data,
        platforms: detail?.platforms,
      }).then((res) => {
        console.log("detail", res?.data);
        if (res?.data) {
          setDetail((old) => ({ ...old, ...data }));
          setPatchState(true);
          setCurrentStep(2);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleType = (data, values) => {
    setSelectedType(data);
    values.type = data.value;
  };

  const loadOptions = async (text = "", callback) => {
    if (text?.length > 1) {
      return fetch(`https://api.geoapify.com/v1/geocode/autocomplete?text=${text}&apiKey=${process.env.REACT_APP_SEARCH_KEY}&filter=countrycode:ca`, { method: "GET" })
        .then(response => response.json())
        .then(result => {
          const temp = [];
          result?.features?.map(data => {
            temp.push({
              label: data?.properties?.formatted,
              line1: data?.properties?.address_line1,
              line2: data?.properties?.address_line2,
              city: data?.properties?.city,
              province: data?.properties?.state,
              country: data?.properties?.country,
              zip: data?.properties?.postcode,
              value: data?.properties?.formatted
            });
          });
          return temp.filter(address => address.zip.length >= 6);
        })
        .catch(error => console.log('error', error));
    } else {
      callback(null);
    }
  };

  const onSelectHandler = (data, values) => {
    const { line1, line2, city, province, country, zip } = data;
    setAdd(data);
    values.address = { line1, line2, city, province, country, zip };
  };

  const handleWheel = (e) => {
    e.preventDefault();
    return;
  };

  return (
    <div className='propertyForm'>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ errors, values, touched, setFieldValue, submitForm }) => (
          <Form>
            <Row>
              <Col xs={12} md={6}>
                <FormikControl
                  label="Address Line 1"
                  placeholder="address"
                  type="text"
                  id="addressinput"
                  name="line1"
                  value={values?.line1}
                />
              </Col>
              <Col xs={12} md={6}>
                <FormikControl
                  label="Address Line 2"
                  placeholder="apt, unit, etc. (Optional)"
                  type="text"
                  id="addressinput"
                  name="line2"
                  value={values?.line2}
                />
              </Col>
              <Col xs={12} md={4}>
                <FormikControl
                  label="City"
                  placeholder="city"
                  type="text"
                  id="city"
                  name="city"
                  value={values.city}
                />
              </Col>
              <Col xs={12} md={4}>
                <label>Province</label>
                <div className={`typeBusiness_inputBox`}>
                  <Select
                    options={province}
                    onBlur={() => setClicked(true)}
                    className="searchBar"
                    classNamePrefix="select"
                    name="province"
                    placeholder="select province"
                    value={selectedProvince}
                    onChange={(selectedOption) => {
                      setFieldValue('province', selectedOption.value);
                      setSelectedProvince(selectedOption);
                    }}
                  />
                </div>
                {errors.province && touched.province && (
                  <Errormsg>{errors.province}</Errormsg>
                )}
              </Col>
              <Col xs={12} md={4}>
                <FormikControl
                  label="Postal Code"
                  placeholder="postal code"
                  type="text"
                  id="postalCode"
                  name="zip"
                  value={values.zip}
                />
              </Col>
              <Col xs={12} md={4}>
                <label>Property type</label>
                <div className={`typeBusiness_inputBox`}>
                  <Select
                    options={propType}
                    onBlur={() => setClicked(true)}
                    className="searchBar"
                    classNamePrefix="select"
                    name="type"
                    placeholder="select property type"
                    value={selectedType}
                    onChange={(data) => { handleType(data, values); }}
                  />
                </div>
              </Col>
              <Col xs={12} md={4} lg={4}>
                <FormikControl
                  label="Number of rooms"
                  placeholder="3"
                  type="number"
                  className="numberInput"
                  name="bedroom"
                  value={values.bedroom}
                />
              </Col>
              <Col xs={12} md={4} lg={4}>
                <FormikControl
                  label="Number of Bath rooms"
                  type="number"
                  placeholder="2.5"
                  className="numberInput"
                  name="bathroom"
                  value={values.bathroom}
                />
              </Col>
              <Col xs={12} md={4} lg={4}>
                <FormikControl
                  label="Available Date"
                  type="date"
                  placeholder="2023/08/12"
                  name="availability"
                  value={new Date(values.availability).toLocaleDateString('en-ca', { timeZone: 'UTC' })}
                />
              </Col>
              <Col xs={12} md={6} lg={4}>
                <FormikControl
                  label="Square Footage"
                  placeholder="2200"
                  type="number"
                  className="numberInput"
                  name="sqft"
                  value={values.sqft}
                />
              </Col>
              <Col xs={12} md={6} lg={4}>
                <FormikControl
                  label="Rent $ (monthly)"
                  placeholder="4500"
                  type="number"
                  className="numberInput"
                  name="rent"
                  value={values.rent}
                />
              </Col>
            </Row>
            <div className='button_margin'>
              <div className='d-flex align-items-center justify-content-end mb-5'>
                {(propertyId == null) ?
                  <CommonButton
                    text="Next"
                    type="button"
                    id="addStepSubmit"
                    onClick={() => {
                      setClicked(true);
                      submitForm();

                    }}
                  />
                  :
                  <CommonButton text="Next" type="submit" id="addStepSubmit" onClick={() => { setClicked(true); }} />
                }
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddStep1;


