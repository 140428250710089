import React from 'react';
import './Select.module.scss'
import { useState } from 'react';
import styles from './Select.module.scss';

const Select = ({ name, options, ...rest }) => {
  const [timezone, setTimezone] = useState('')

  //点击高亮
  const handleChange = (timezone) => {
    //1. test 
    // console.log(timeZone.target.value)
    setTimezone(timezone.target.value);
  };



  return (
    <div className="input_group_inner">
      <select
        name={name}
        id={name}
        className={`form-control ${rest.value ? styles.selected : ''}`}
        onChange={(e) => {
          handleChange(e);
          rest?.handleChange(e);
        }}
        value={rest.value}
      >
        {options.map(option => (
          <option
            key={option.value}
            value={option.value}
          >
            {option.key}
          </option>
        ))}
      </select>
      <span className="righticon" />
    </div>
  );
};

export default Select;