import React from 'react'
import "./PropertySteps.scss"
import { Link } from 'react-router-dom'
import CommonButton from '../../../common/CommonButton/CommonButton'

const PropertySteps = (props) => {
  const { currentStep } = props
  return (
    <>
      <div className='propertySteps'>
        {/* <div className='d-flex align-items-center justify-content-between mb-5'>
                <CommonButton text="Back" className="grey-btn" type="submit"/>
                <CommonButton text="Next" />
            </div> */}
        <ul>
          <li><Link to="#" className={`${currentStep === 1 ? 'active' : ''}`} ></Link></li>
          <li><Link to="#" className={`${currentStep === 2 ? 'active' : ''}`} ></Link></li>
          <li><Link to="#" className={`${currentStep === 3 ? 'active' : ''}`} ></Link></li>
          <li><Link to="#" className={`${currentStep === 4 ? 'active' : ''}`} ></Link></li>
        </ul>
      </div>
    </>
  )
}

export default PropertySteps
