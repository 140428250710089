import { lazy } from 'react';
import MainLayout from '../components/layouts/MainLayout/MainLayout';
import NotFound from '../components/pages/NotFound/NotFound';
import NotRecord from '../components/pages/NoRecord/NotRecord';
// import Home from '../components/pages/Home/Home';
import Signup from '../components/pages/Signup/Signup';
import Dashboard from '../components/pages/Dashboard/Dashboard';
import Property from '../components/pages/Property/Property';
import PropertyDetails from '../components/pages/PropertyDetails/PropertyDetails';
import Chats from '../components/pages/Chats/Chats';
import Profile from '../components/pages/profile/Profile';
const LoginLayout = lazy(() =>
  import('../components/layouts/LoginLayout/LoginLayout'),
);
const Login = lazy(() => import('../components/pages/Login/Login'));
// const Preview = lazy(() => import('../components/pages/Preview/Preview'));
// const currentPath = window.location.pathname;
export const routes = [
  {
    path: '',
    element: <Login />,
    children: [
      {
        path: '/login',
        element: <Login />,
      },
    ],
  },
  {
    path: '/signup',
    element: <Signup />,
  },
  {
    path: '/dashboard',
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: 'property',
        element: <Property />,
      },
      {
        path: 'property-details/:id',
        element: <PropertyDetails />,
      },
      {
        path: 'profile',
        element: <Profile />,
      },
      {
        path: 'chats',
        element: <Chats />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
      {
        path: 'no-record',
        element: <NotRecord />,
      },
    ],
  },
];
