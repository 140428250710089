import React, { useState } from "react";
import facebookIcon from "../../../../assets/images/fbBlue.png";
import zumperIcon from "../../../../assets/images/icons1.png";
import craigslistIcon from "../../../../assets/images/craigslist.png";
import "./ChatsUserList.scss";
import { appUrl, assets, avatarPath, tenantPath, userPath } from "../../../../utils/config";
import dummyProfilePic from "../../../../assets/images/dummyProfilePic.webp";
import { TagEditIcon } from "../../../../assets/svgs/svg";
import { useSearchParams } from "react-router-dom";
import { TopChannelIcon } from "../../../../assets/svgs/svg";

const ChatsUserList = (props) => {
  const {
    chatList = [],
    getChats,
    setRecieverUser,
    setSelectedList,
    selectedList,
    handleMenuToggle,
    searchTerm,
    getChatlistAll,
    id,
    onPin,
  } = props;
  useState(() => {
    //
  }, [chatList]);

  const [contextMenu, setContextMenu] = useState(null);

  const platformIcon = (type) => {
    switch (type) {
      case "facebook":
        return <img src={facebookIcon} alt="icon" />;
      case "craigslist":
        return <img src={craigslistIcon} alt="icon" />;
      case "zumper":
        return <img src={zumperIcon} alt="icon" />;
      default:
        return <img src={facebookIcon} alt="icon" />;
    }
  };

  const handleContextMenu = (event, item) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      item: item,
    });
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const handlePin = (pinStatus) => {
    if (!contextMenu || !contextMenu.item) {
      console.error("Invalid contextMenu or item structure:", contextMenu);
      return;
    }


    // 构建更新后的用户对象
    const updatedItem = {
      ...contextMenu.item,
      tags: pinStatus ? ["pinned"] : []
    };

    // 发送 POST 请求更新 pinned 状态
    fetch(`${appUrl}/messages/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedItem),
    })
      .then((response) => response.json())
      .then((data) => {
        // 处理成功响应
        console.log("Pinned status updated successfully:", data);

        // setChatList(updatedChatList);
      })
      .catch((error) => {
        // 处理错误
        console.error("Error updating pinned status:", error);
      });

    handleCloseContextMenu();
  };



  const filteredChatList = chatList.filter((item) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      item?.fname.toLowerCase().includes(searchLower) ||
      item?.platform.toLowerCase().includes(searchLower)
    );
  });


  return (
    <>
      <div className="chatsUserList" >
        <ul>
          {filteredChatList && filteredChatList.length > 0 ? (
            filteredChatList.map((item, i) => {
              // console.log("User properties: ", item);
              return (
                <li
                  key={item.id}
                  className={`${selectedList === i ? "highlighted" : ""} 
                              ${item.unread > 0 ? "unreadMsg" : ""}
                              ${item.tags?.includes("pinned") ? "pinned-item" : ""}`
                  }
                  onClick={() => {
                    setSelectedList(i);
                    getChats(item?.id, true);
                    setRecieverUser(item);
                    handleMenuToggle();
                    //这里应该写个函数前端自定义排序，而不是不停的调用后端
                    getChatlistAll(id)
                  }}
                  onContextMenu={(e) => handleContextMenu(e, item)}
                >
                  <div className="d-flex align-items-center">
                    {/* <span className='unreadMsg'></span> */}
                    <span className="platformIcon">
                      {
                        platformIcon(item?.platform)
                      }
                    </span>
                    <span className="UserIcon">
                      <img src={(item.avatar === undefined) ? dummyProfilePic : appUrl + '/' + avatarPath + '/' + tenantPath + '/' + item.avatar} alt="icon" />
                    </span>
                    <h6 className="userName">{item?.fname}</h6>

                  </div>


                  {/* <div className="align-items-right">
                    <span className="tag1"></span>
                    <span className="tag2"></span>
                  </div> */}
                </li>
              );
            })
          ) : (
            <h6>No Chats Found Related To Property</h6>
          )}
        </ul>
        {/* {contextMenu ? (
          <div
            className="context-menu"
            style={{
              top: contextMenu.mouseY - 50, // 往上移动10像素，可以根据需要调整
              left: contextMenu.mouseX + 10,
              position: "absolute",
              zIndex: 1000,
              backgroundColor: "white",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
              padding: "5px",
              borderRadius: "5px", // 添加圆角样式
            }}
            onClick={handleCloseContextMenu}
          >
            <button
              onClick={() => handlePin(!contextMenu.item.tags?.includes("pinned"))}
              style={{
                border: "1px solid #ccc", // 设置浅色边框
                borderRadius: "5px", // 设置按钮的圆角，保持一致
                padding: "5px 10px", // 调整按钮的内边距
                cursor: "pointer", // 添加指针样式
                backgroundColor: "transparent", // 使按钮背景透明
              }}
            >
              {contextMenu.item.tags?.includes('pinned') ? 'Unpin' : 'Pin'}
            </button>
          </div>
        ) : null} */}
      </div>
    </>
  );
};

export default ChatsUserList;



