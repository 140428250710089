// import React, { useEffect, useState } from 'react';
// import './Dashboard.scss';
// import CommonHeading from '../../common/CommonHeading/CommonHeading';
// import { Container } from 'react-bootstrap';
// import MyPropertiesCard from './MyPropertiesCard/MyPropertiesCard';
// import { Toaster } from 'react-hot-toast';
// import { http } from '../../../utils/http'; // Ensure this import is correct based on your project structure
// import SearchBox from '../../common/SearchBox/searchBox';

// const Dashboard = () => {
//   const [properties, setProperties] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [searchTerm, setSearchTerm] = useState('');

//   useEffect(() => {
//     getPropertyList();
//   }, []);

//   const getPropertyList = async () => {
//     setLoading(true);
//     try {
//       const res = await http.get('/properties');
//       if (res?.status === 200) {
//         setProperties(res?.data);
//       }
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleSearchChange = (e) => {
//     setSearchTerm(e.target.value);
//   };

//   const handleConfirmSearch = () => {
//     console.log('Search confirmed with term:', searchTerm);
//   };

//   const handleClearSearch = () => {
//     setSearchTerm('');
//   };

//   const filteredProperties = properties.filter(property => {
//     const search = searchTerm.toLowerCase();
//     return (
//       property.address.line1.toLowerCase().includes(search) ||
//       property.status.toLowerCase().includes(search)

//     );
//   });

//   return (
//     <>
//       <div className="dashboardPage">
//         <Container>
//           <div className="title">
//             <CommonHeading heading="My Properties" />
//             {properties.length > 0 && (

//               <div className="searchBox">
//                 <SearchBox
//                   searchTerm={searchTerm}
//                   setSearchTerm={setSearchTerm}
//                   onSearch={handleConfirmSearch}
//                   onClear={handleClearSearch}
//                 />
//               </div>

//             )}
//           </div>
//           <MyPropertiesCard properties={filteredProperties} loading={loading} searchTerm={searchTerm} refreshProperties={getPropertyList} />
//         </Container>
//       </div>
//       <Toaster />
//     </>
//   );
// };

// export default Dashboard;

import React, { useEffect, useState } from 'react';
import './Dashboard.scss';
import CommonHeading from '../../common/CommonHeading/CommonHeading';
import { Container } from 'react-bootstrap';
import MyPropertiesCard from './MyPropertiesCard/MyPropertiesCard';
import { Toaster } from 'react-hot-toast';
import { http } from '../../../utils/http'; // Ensure this import is correct based on your project structure
import SearchBox from '../../common/SearchBox/searchBox';

const Dashboard = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    getPropertyList();
  }, []);

  const getPropertyList = async () => {
    setLoading(true); // Ensure loading is set to true at the start of the request
    try {
      const res = await http.get('/properties');
      if (res?.status === 200) {
        setProperties(res?.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleConfirmSearch = () => {
    console.log('Search confirmed with term:', searchTerm);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const filteredProperties = properties.filter(property => {
    const search = searchTerm.toLowerCase();
    return (
      property.address.line1.toLowerCase().includes(search) ||
      property.status.toLowerCase().includes(search)
    );
  });

  return (
    <>
      <div className="dashboardPage">
        <Container>
          <div className="title">
            <CommonHeading heading="My Properties" />
            {properties.length > 0 && (
              <div className="searchBox">
                <SearchBox
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  onSearch={handleConfirmSearch}
                  onClear={handleClearSearch}
                />
              </div>
            )}
          </div>
          <MyPropertiesCard properties={filteredProperties} loading={loading} searchTerm={searchTerm} refreshProperties={getPropertyList} />
        </Container>
      </div>
      <Toaster />
    </>
  );
};

export default Dashboard;


