import React, { useEffect } from 'react'
import FormikControl from '../../common/formik/FormikControl';
import { Form, Formik } from 'formik';
import googleicon from '../../../assets/images/google-icon.svg';
import CommonButton from '../../common/CommonButton/CommonButton';
import { BackarrowIcon, ErrorIcon, FacebookIcon, GreencheckIcon, LockIcon, MailIcon } from '../../../assets/svgs/svg';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
import { http } from '../../../utils/http';
import { emailRegex, isLoggedIn, isValidEmail } from '../../../utils/helper';

const SignupForm = (props) => {
    const { setState } = props
    const navigate = useNavigate();
    useEffect(() => {
        if (isLoggedIn()) {
            navigate("/dashboard")
        }
    }, [])
    const validationSchema = Yup.object({
        fname: Yup.string().required('This field is required'),
        lname: Yup.string().required('This field is required'),
        email: Yup.string().matches(emailRegex, 'Invalid email').required('This field is required'),
        password: Yup.string().required('This field is required')
    });
    const initialValues = {
        fname: '',
        lname: '',
        email: '',
        password: ''
    };
    const handleSubmit = (values, submitProps) => {
        let body = {
            email: values?.email,
            fname: values?.fname,
            lname: values?.lname,
            password: values?.password
        };
        try {
            http.post('/signup', body).then((res) => {
                submitProps.setSubmitting(false);
                if (res?.status === 200) {
                    toast.success("Please check your email to complete the signup process.");
                    navigate("/login")
                    // setState(false)
                }
            });
        } catch (error) {
            submitProps.setSubmitting(false);
            console.error("error", error);
        }
    };

    return (
        <>
            {
                <Formik
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {(formik) => (
                        <Form>
                            <Row>
                                {/* <Col xs={12}>
                                    <h6>Sign in with Open accounts</h6>
                                </Col>
                                <Col xs={6}>
                                    <button
                                        isLoading={formik.isSubmitting}
                                        disabled={!formik.isValid}
                                        type="submit"
                                        className="w-100 mt-4 white-btn"
                                    >
                                        <img
                                            className="me-3"
                                            src={googleicon}
                                            alt="Google"
                                        />{' '}
                                        Google
                                    </button>
                                </Col>
                                <Col xs={6}>
                                    <button
                                        isLoading={formik.isSubmitting}
                                        disabled={!formik.isValid}
                                        type="submit"
                                        className="w-100 mt-4 white-btn"
                                    >
                                        <span className="me-3"><FacebookIcon /></span>
                                        Facebook
                                    </button>
                                </Col>
                            </Row>
                            <div className="my-5">
                                <hr />
                            </div>
                            <Row>
                                <Col xs={12} className="mb-4 pb-2">
                                    <h6>Or continue with email address</h6>
                                </Col> */}
                                <Col xs={6} md={6}>
                                    <FormikControl
                                        type="text"
                                        placeholder="First Name"
                                        name="fname"
                                        innerClass="iconBoxinputnew"
                                    />
                                </Col>
                                <Col xs={6} md={6}>
                                    <FormikControl
                                        type="text"
                                        placeholder="Last Name"
                                        name="lname"
                                        innerClass="iconBoxinputnew"
                                    />
                                </Col>
                                <Col xs={12}>
                                    <FormikControl
                                        type="email"
                                        placeholder="Enter your email"
                                        name="email"
                                        innerClass={`iconBoxinput ${!formik.isValid && formik?.values?.email ? 'error_msgBox' : ''}`}
                                        lefticonshow={true}
                                        lefticon={
                                            <span>
                                                {!formik?.values?.email ? <small className='WithoutErrIcon'><MailIcon /></small> :
                                                    <small className='ErrorMsgicon' onClick={() => { formik.setFieldValue("email", "") }}><BackarrowIcon /></small>}
                                                {/*  */}
                                            </span>
                                        }
                                        righticonshow={formik?.values?.email}
                                        righticon={
                                            <span>
                                                {isValidEmail(formik.values.email) ?
                                                    <small className='WithoutErrIcon'><GreencheckIcon /></small> : <small className='ErrorMsgicon'><ErrorIcon /></small>
                                                }
                                            </span>
                                        }
                                    />
                                </Col>
                                <Col xs={12}>
                                    <div className='iconBoxinput'>
                                        <FormikControl
                                            control="password"
                                            name="password"
                                            placeholder="Password"
                                            className="mb-4"
                                            lefticonshow={true}
                                            lefticon={<LockIcon />}
                                            innerClass="iconBoxinput"
                                        />
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <CommonButton
                                        isLoading={formik?.isSubmitting}
                                        type="submit"
                                        text="Continue"
                                        fluid={true}
                                        disabled={!isValidEmail(formik.values.email)}
                                    />
                                </Col>
                            </Row>
                            <div className='googleCaptcha mt-5'>
                                <p>This site is protected by reCAPTCHA and the Google Privacy Policy.</p>
                            </div>
                        </Form>
                    )}
                </Formik>
            }
        </>
    )
}

export default SignupForm
