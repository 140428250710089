import React, { useState } from 'react';
import { SearchIcon, ClearIcon } from "../../../assets/svgs/svg";
import "./searchBox.scss"
const SearchBox = ({ searchTerm, setSearchTerm, onSearch, onClear }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="search-box">
      <div className="search-box_search">
        <span onClick={onSearch} className="search-icon-wrapper">
          <SearchIcon />
        </span>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          className="search-input"
        />
        {searchTerm && (
          <span onClick={onClear} className="remove-icon-wrapper">
            <ClearIcon />
          </span>
        )}
      </div>
    </div>
  );
};

export default SearchBox;