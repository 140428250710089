// import React, { useState } from "react";
// import ImageIcon from "../../../../assets/images/no-image.svg";
// import {
// 	ArrowRightgreyIcon,
// 	DeleteIcon,
// 	EditIcon,
// 	ViewIcon,
// 	ActiveIcon,
// 	DraftIcon
// } from "../../../../assets/svgs/svg";
// import { Link } from "react-router-dom";
// import "./MyPropertiesCard.scss";
// import { Col, Row } from "react-bootstrap";
// import NotRecord from "../../NoRecord/NotRecord";
// import Loader from "../../../common/Loader/Loader";
// import { truncateString } from "../../../../utils/helper";
// import fbGray from "../../../../assets/images/fb-gray.png";
// import fbColored from "../../../../assets/images/fb-colored.png";
// import craigslistGray from "../../../../assets/images/craigs-list-gray.png";
// import craigslistColored from "../../../../assets/images/craigs-list-colored.png";
// import { appUrl, propertyPath } from "../../../../utils/config";
// import { toast } from "react-hot-toast";
// import CustomModal from "../../../common/CustomModal/CustomModal"; // 引入自定义弹窗组件
// import { http } from "../../../../utils/http";

// const MyPropertiesCard = ({ properties, loading, searchTerm, refreshProperties }) => {
// 	// const [localProperties, setLocalProperties] = useState(properties);
// 	const [showModal, setShowModal] = useState(false);
// 	const [modalMessage, setModalMessage] = useState("");
// 	const [selectedPropertyId, setSelectedPropertyId] = useState(null); // 用于保存选中的属性ID
// 	const [notification, setNotification] = useState({ show: false, message: "", id: "" });

// 	const deleteHandler = (id) => {
// 		setSelectedPropertyId(id);
// 		if (id) {
// 			setModalMessage("Are you sure you want to delete this property?");
// 			setShowModal(true);
// 		}
// 	};

// 	const confirmDelete = async () => {
// 		if (selectedPropertyId) {
// 			try {
// 				http.delete(`/property/${selectedPropertyId}`);
// 				toast.success("Removed successfully !!");
// 				refreshProperties();
// 				setShowModal(false);
// 			} catch (error) {
// 				console.error(error);
// 				setShowModal(false);
// 			}
// 		}
// 	};

// 	const handleCloseModal = () => setShowModal(false);

// 	const handleGrayIconClick = (id) => {
// 		setNotification({ show: true, message: `Posting in Progress`, id });
// 		setTimeout(() => setNotification({ show: false, message: "", id: "" }), 2000); // Clear notification after 2 seconds
// 	}
// 	return (
// 		<>
// 			{loading ? (
// 				<Loader />
// 			) : (
// 				<div className="myPropertiescard">
// 					{properties && properties.length ? (
// 						properties.map((item, i) => (
// 							<div key={i} className="propertiesBox">
// 								<Row className="align-items-center">
// 									<Col xs={12} md={6}>
// 										<div className="d-flex">
// 											<div className="status"> {item.status === 'active' ? <ActiveIcon /> : <DraftIcon />}</div>
// 											<figure className="propertiesImg">
// 												<img
// 													src={
// 														item?.photos?.length
// 															? `${appUrl}/${propertyPath}/${item?._id}/photo/${item?.photos[0]}`
// 															: ImageIcon
// 													}
// 													className="figureImg"
// 													alt=""
// 												/>
// 											</figure>
// 											<div className="propertiesContent">
// 												<h3>{truncateString(item?.address?.line1 || "")}</h3>
// 												<p>CAD {item?.rent} / month</p>
// 											</div>
// 										</div>
// 									</Col>
// 									<Col xs={12} md={6} className="pt-2 pt-md-0 mt-4 mt-md-0">
// 										<div
// 											className="d-flex align-items-center justify-content-between justify-content-md-end gap-3 right-icon-social"
// 											onClick={(e) => {
// 												if (
// 													e.target.tagName !== "A" &&
// 													e.target.tagName !== "IMG" &&
// 													!e.target.closest("a")
// 												) {
// 													window.location.href = `/dashboard/chats?id=${item?._id}`;
// 												}
// 											}}
// 											style={{ cursor: "pointer" }}
// 										>
// 											{typeof item?.platforms?.facebook?.link === "string" ? (
// 												<a
// 													href={item?.platforms?.facebook?.link}
// 													target="_blank"
// 													className={`${typeof item?.platforms?.facebook?.link !== "string" && "grey-icon"}`}
// 												>
// 													<img src={fbColored} alt="facebook" />
// 												</a>
// 											) : null}
// 											{item?.platforms?.facebook === true ? (
// 												<div className="notification-container">
// 													<div onClick={() => handleGrayIconClick(item._id)}>
// 														<img src={fbGray} alt="facebook" />
// 													</div>
// 												</div>
// 											) : null}
// 											{typeof item?.platforms?.craigslist?.link === "string" ? (
// 												<a
// 													href={item?.platforms?.craigslist?.link}
// 													target="_blank"
// 													className={`  ${typeof item?.platforms?.craigslist?.link !== "string" && "grey-icon"}`}
// 												>
// 													<img src={craigslistColored} alt="craigslistLogo" className="craigslistLogo" />
// 												</a>
// 											) : null}
// 											{item?.platforms?.craigslist === true ? (
// 												<div className="notification-container">
// 													<img
// 														src={craigslistGray}
// 														alt="craigslistLogo"
// 														className="craigslistLogo"
// 														onClick={() => handleGrayIconClick(item._id)}
// 													/>
// 												</div>
// 											) : null}
// 											<div style={{ flexGrow: 1 }}></div>
// 											<Link
// 												className="viewMode"
// 												to={`/dashboard/property-details/${item?._id}`}
// 												onClick={(e) => {
// 													e.stopPropagation();
// 												}}
// 											>
// 												<ViewIcon />
// 											</Link>
// 											<Link
// 												to={`/dashboard/property?id=${item?._id}`}
// 												onClick={(e) => {
// 													e.stopPropagation();
// 												}}
// 											>
// 												<EditIcon />
// 											</Link>
// 											<Link
// 												to="#"
// 												onClick={(e) => {
// 													e.stopPropagation();
// 													deleteHandler(item?._id);
// 												}}
// 											>
// 												<DeleteIcon />
// 											</Link>
// 											<Link to={`/dashboard/chats?id=${item?._id}`} onClick={() => console.log("testing jump to chat")}>
// 												<ArrowRightgreyIcon />
// 											</Link>
// 										</div>
// 									</Col>
// 								</Row>
// 							</div>
// 						))
// 					) : searchTerm ? (
// 						<NotRecord text="No properties found matching your search criteria." />
// 					) : (
// 						<NotRecord text="Please add your first property!" />
// 					)}
// 				</div>
// 			)}
// 			<CustomModal
// 				show={showModal}
// 				handleClose={handleCloseModal}
// 				handleSave={confirmDelete}
// 				message={modalMessage}
// 				saveButton={"Ok"}
// 			/>
// 		</>
// 	);
// };

// export default MyPropertiesCard;


import React, { useState } from "react";
import ImageIcon from "../../../../assets/images/no-image.svg";
import {
	ArrowRightgreyIcon,
	DeleteIcon,
	EditIcon,
	ViewIcon,
	ActiveIcon,
	DraftIcon
} from "../../../../assets/svgs/svg";
import { Link } from "react-router-dom";
import "./MyPropertiesCard.scss";
import { Col, Row } from "react-bootstrap";
import NotRecord from "../../NoRecord/NotRecord";
import Loader from "../../../common/Loader/Loader";
import { truncateString } from "../../../../utils/helper";
import fbGray from "../../../../assets/images/fb-gray.png";
import fbColored from "../../../../assets/images/fb-colored.png";
import craigslistGray from "../../../../assets/images/craigs-list-gray.png";
import craigslistColored from "../../../../assets/images/craigs-list-colored.png";
import { appUrl, propertyPath } from "../../../../utils/config";
import { toast } from "react-hot-toast";
import CustomModal from "../../../common/CustomModal/CustomModal"; // 引入自定义弹窗组件
import { http } from "../../../../utils/http";

const MyPropertiesCard = ({ properties, loading, searchTerm, refreshProperties }) => {
	const [showModal, setShowModal] = useState(false);
	const [modalMessage, setModalMessage] = useState("");
	const [selectedPropertyId, setSelectedPropertyId] = useState(null); // 用于保存选中的属性ID
	const [notification, setNotification] = useState({ show: false, message: "", id: "" });

	const deleteHandler = (id) => {
		setSelectedPropertyId(id);
		if (id) {
			setModalMessage("Are you sure you want to delete this property?");
			setShowModal(true);
		}
	};

	const confirmDelete = async () => {
		if (selectedPropertyId) {
			try {
				await http.delete(`/property/${selectedPropertyId}`);
				toast.success("Removed successfully !!");
				setShowModal(false);
				refreshProperties();
			} catch (error) {
				console.error(error);
				toast.error("Failed to remove the property.");
				setShowModal(false);
			}
		}
	};

	const handleCloseModal = () => setShowModal(false);

	const handleGrayIconClick = (id) => {
		setNotification({ show: true, message: `Posting in Progress`, id });
		setTimeout(() => setNotification({ show: false, message: "", id: "" }), 2000); // Clear notification after 2 seconds
	}

	return (
		<>
			{loading ? (
				<Loader />
			) : (
				<div className="myPropertiescard">
					{properties && properties.length ? (
						properties.map((item, i) => (
							<div key={i} className="propertiesBox">
								<Row className="align-items-center">
									<Col xs={12} md={6}>
										<div className="d-flex">
											<div className="status"> {item.status === 'active' ? <ActiveIcon /> : <DraftIcon />}</div>
											<figure className="propertiesImg">
												<img
													src={
														item?.photos?.length
															? `${appUrl}/${propertyPath}/${item?._id}/photo/${item?.photos[0]}`
															: ImageIcon
													}
													className="figureImg"
													alt=""
												/>
											</figure>
											<div className="propertiesContent">
												<h3>{truncateString(item?.address?.line1 || "")}</h3>
												<p>CAD {item?.rent} / month</p>
											</div>
										</div>
									</Col>
									<Col xs={12} md={6} className="pt-2 pt-md-0 mt-4 mt-md-0">
										<div
											className="d-flex align-items-center justify-content-between justify-content-md-end gap-3 right-icon-social"
											onClick={(e) => {
												if (
													e.target.tagName !== "A" &&
													e.target.tagName !== "IMG" &&
													!e.target.closest("a")
												) {
													window.location.href = `/dashboard/chats?id=${item?._id}`;
												}
											}}
											style={{ cursor: "pointer" }}
										>
											{typeof item?.platforms?.facebook?.link === "string" ? (
												<a
													href={item?.platforms?.facebook?.link}
													target="_blank"
													className={`${typeof item?.platforms?.facebook?.link !== "string" && "grey-icon"}`}
												>
													<img src={fbColored} alt="facebook" />
												</a>
											) : null}
											{item?.platforms?.facebook === true ? (
												<div className="notification-container">
													<div onClick={() => handleGrayIconClick(item._id)}>
														<img src={fbGray} alt="facebook" />
													</div>
												</div>
											) : null}
											{typeof item?.platforms?.craigslist?.link === "string" ? (
												<a
													href={item?.platforms?.craigslist?.link}
													target="_blank"
													className={`  ${typeof item?.platforms?.craigslist?.link !== "string" && "grey-icon"}`}
												>
													<img src={craigslistColored} alt="craigslistLogo" className="craigslistLogo" />
												</a>
											) : null}
											{item?.platforms?.craigslist === true ? (
												<div className="notification-container">
													<img
														src={craigslistGray}
														alt="craigslistLogo"
														className="craigslistLogo"
														onClick={() => handleGrayIconClick(item._id)}
													/>
												</div>
											) : null}
											<div style={{ flexGrow: 1 }}></div>
											<Link
												className="viewMode"
												to={`/dashboard/property-details/${item?._id}`}
												onClick={(e) => {
													e.stopPropagation();
												}}
											>
												<ViewIcon />
											</Link>
											<Link
												to={`/dashboard/property?id=${item?._id}`}
												onClick={(e) => {
													e.stopPropagation();
												}}
											>
												<EditIcon />
											</Link>
											<Link
												to="#"
												onClick={(e) => {
													e.stopPropagation();
													deleteHandler(item?._id);
												}}
											>
												<DeleteIcon />
											</Link>
											<Link to={`/dashboard/chats?id=${item?._id}`} onClick={() => console.log("testing jump to chat")}>
												<ArrowRightgreyIcon />
											</Link>
										</div>
									</Col>
								</Row>
							</div>
						))
					) : searchTerm ? (
						<NotRecord text="No properties found matching your search criteria." />
					) : (
						<NotRecord text="Please add your first property!" />
					)}
				</div>
			)}
			<CustomModal
				show={showModal}
				handleClose={handleCloseModal}
				handleSave={confirmDelete}
				message={modalMessage}
				saveButton={"Ok"}
			/>
		</>
	);
};

export default MyPropertiesCard;
