import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
// import Footer from '../../common/Footer/Footer';
import Header from '../../common/Header/Header';
import styles from './MainLayout.module.scss';
import { isLoggedIn } from '../../../utils/helper';
import { http } from '../../../utils/http';

const MainLayout = () => {
  const navigate = useNavigate()
  const [image, setImage] = useState(null);
  
  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/login")
    } else {
      getUserDetail()
    }
  }, [])

  const getUserDetail = () => {
    try {
      http.get('/profile').then((res) => {
        if (res?.status === 200) {
          setImage(res?.data?.avatar)
        }
      })
    } catch (error) {
      console.error("error", error);
    }
  }
  return (
    <>
      <div className={styles.main_layout}>
        <Header image={image}/>
        <div className={styles.main_layout_inner}>
          <Outlet />
        </div>
        {/* <Footer /> */}
      </div>
    </>
  );
};
export default MainLayout;
