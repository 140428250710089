import React, { useState } from 'react';
import './Signup.scss';
import { Col, Row } from 'react-bootstrap';
import { CheckcircleIcon, LogoIcon } from '../../../assets/svgs/svg';
import { Link } from 'react-router-dom';
import signimg from "../../../assets/images/signupimg.svg";
import SignupForm from './SignupForm';
import SignupOtp from './SignupOtp';

const Signup = () => {
  const [state, setState] = useState(true)
  return (
    <>
      <div className='common_login_screens signupPage'>
        <Row className=''>
          <Col xs={12} lg={4} className='d-none d-lg-flex'>
            <div className='signUpBox w-100'>
              <Link className='signUpLogo d-none d-lg-block' to="/dashboard"><LogoIcon /></Link>
              <div className='signUpContent'>
                <img src={signimg} alt='img' />
                <h2>Project name</h2>
                <ul>
                  <li><CheckcircleIcon /> Project Features</li>
                  <li><CheckcircleIcon /> Project Features</li>
                  <li><CheckcircleIcon /> Project Features</li>
                  <li><CheckcircleIcon /> Project Features</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={8} className='d-flex'>
            <div className='login_Col w-100'>
              <div className='donotAccount'>
                <Link className='signUpLogo d-block d-lg-none' to="/dashboard"><LogoIcon /></Link>
                <p>Already have an account? <Link to="/login">Sign In</Link></p>
              </div>
              <div className='login_box'>
                <div className='login_box_heading'>
                  <Link className='d-none d-lg-block' to="/dashboard">
                    <LogoIcon />
                  </Link>
                  <h2>Sign up</h2>
                </div>
                <div className={`${state?'d-block':'d-none'}`}>
                  <SignupForm setState={setState}/>
                </div>
                <div className={`${state?'d-none':'d-block'}`}>
                  <SignupOtp setState={setState}/>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Signup;
