import React from 'react'
import { Form } from 'react-bootstrap'
import "./CheckBox.scss"

const CheckBox = ({ label, option, className, id, onChange, checked, name }) => {
  return (
    <>
      <div className={`checkBox ${className}`}>
        <Form.Check className='commonCheckbox' checked={checked} aria-label={option} label={label} id={id || ""} name={name} onChange={onChange} />
      </div>
    </>
  )
}

export default CheckBox
