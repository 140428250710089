
import React, { useEffect, useState } from 'react';
import { Descriptions, Image } from 'antd';
import { http } from "../../../../utils/http";
import CommonButton from "../../../common/CommonButton/CommonButton";
import fbColored from "../../../../assets/images/fb-colored.png";
import craigslistColored from "../../../../assets/images/craigs-list-colored.png";
import { windowSize } from "../../../../utils/helper"
import { Carousel } from "react-bootstrap";
import "./MetricsCard.scss";
import {
    BedIcon, BathIcon, SqftIcon, FurnishedIcon, NoFurnishedIcon,
    CatIcon, NoCatIcon, DogIcon, NoDogIcon, SmokingIcon, NoSmokingIcon,
    EvIcon, NoEvIcon, WheelchairIcon, NoWheelchairIcon, ExpandIcon, CollapseIcon
} from '../../../../assets/svgs/svg';

const platformImages = {
    facebook: fbColored,
    craigslist: craigslistColored,
};

const baseURL = 'http://assets.hexon.ai/property/';

const MetricsCard = ({ id }) => {
    const [detail, setDetail] = useState(null);
    const [showDescription, setShowDescription] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);

    const toggleDescription = () => {
        setShowDescription(!showDescription);
    };

    useEffect(() => {
        if (id) {
            // Fetch the property details
            http.get(`/property/${id}`).then((response) => {
                setDetail(response.data);
            }).catch((error) => console.log(error));
        }
    }, [id]);

    if (!detail) {
        return <div>Loading...</div>;
    }

    const address2 = detail.address.line2
        ? `${detail.address.line2}, ${detail.address.city}, ${detail.address.province}, ${detail.address.zip}` : 'N/A';

    const openModal = (photo) => {
        setCurrentImage(photo);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setCurrentImage(null);
    };

    const items = [
        {
            key: '1',
            label: 'Address line 1 ',
            span: windowSize === 'small' ? 3 : 2,
            children: `${detail.address.line1}, ${detail.address.city}, ${detail.address.province}`,
        },
        {
            key: '2',
            label: 'Post Code',
            span: windowSize === 'small' ? 3 : 1,
            children: detail.address.zip,
        },
        {
            key: '3',
            label: 'Address line 2',
            span: windowSize === 'small' ? 3 : 2,
            children: address2,
        },
        {
            key: '4',
            label: 'Availability',
            span: windowSize === 'small' ? 3 : 1,
            children: new Date(detail.availability).toLocaleDateString(),
        },
        {
            key: '5',
            label: 'Photos',
            span: windowSize === 'small' ? 3 : 3,
            contentStyle: {
                display: windowSize === 'small' ? 'block' : 'flex',
                flexWrap: 'wrap',
                gap: '10px', // Adjust the gap between the images as needed
                width: '102%',
                maxHeight: '300px', // Adjust the max height as needed
                overflowY: 'auto', // Enable vertical scrolling if content exceeds max height
                marginBottom: '10px'
            },
            children: (
                <>
                    {windowSize === 'small' ? (detail.photos &&
                        <Carousel>
                            {detail.photos.map((photo, index) => (
                                <Carousel.Item key={index}>
                                    <Image
                                        src={`${baseURL}${id}/photo/${photo}`}
                                    />
                                </Carousel.Item>
                            ))}

                        </Carousel>)
                        : (detail.photos && (
                            <div className="photo-grid">
                                {detail.photos.map((photo, index) => (
                                    <div key={index} className="property_box" onClick={() => openModal(photo)}>
                                        <img
                                            src={`${baseURL}${id}/photo/${photo}`}
                                            alt=""
                                            className="property-image"
                                        />
                                    </div>
                                ))}
                            </div>
                        ))}
                </>
            )
        },
        {
            key: '6',
            label: 'Description',
            span: 3,
            children: (
                <div className="description-container">
                    <div className="description-title">
                        {detail.title} {"\u00A0"}
                        <span onClick={toggleDescription} className="description-toggle">
                            {showDescription ? (
                                <CollapseIcon />
                            ) : (
                                <ExpandIcon />
                            )}
                        </span>
                    </div>
                    {showDescription && <div className='description'>{detail.description}</div>}
                </div>

            ),
        },
        {
            key: '7',
            label: `Rent: (${detail.type})`,
            span: windowSize === 'small' ? 3 : 2,
            children: `$${detail.rent} / month`,
        },
        {
            key: '7',
            label: 'Platforms',
            span: windowSize === 'small' ? 3 : 1,
            children: (
                <span>
                    {Object.entries(detail.platforms).map(([key, value]) => (
                        value ? <img key={key} src={platformImages[key]} alt={key} style={{ marginRight: '10px', width: '28px', height: '28px' }} /> : null
                    ))}
                </span>
            ),
        },
        {
            key: '8',
            label: 'Basic info',
            span: windowSize === 'small' ? 3 : 2,
            children: (
                <div className='property_details'>
                    <div className='property_info'>
                        <div>
                            <BedIcon />{detail.bedroom} beds
                        </div>
                        <div>
                            <BathIcon />{"\u00A0"}{detail.bathroom} baths
                        </div>
                        <div>
                            <SqftIcon />{"\u00A0"}{detail.sqft} ft²
                        </div>
                        <div>
                            {detail.features.furnished ? (
                                <span>
                                    <FurnishedIcon />  Furnished
                                </span>
                            ) : (
                                <span>
                                    <NoFurnishedIcon />  No furnished
                                </span>
                            )}
                        </div>
                    </div>
                    <div className='property_info_1'>
                        <div>
                            {detail.features.cat ? (
                                <span>
                                    <CatIcon /> cats
                                </span>) : (
                                <span>
                                    <NoCatIcon /> No cats
                                </span>)}
                        </div>
                        <div>
                            {detail.features.dog ? (
                                <span>
                                    <DogIcon /> dogs
                                </span>) : (
                                <span>
                                    <NoDogIcon /> No dogs
                                </span>)}
                        </div>
                        <div>
                            {detail.features.smoking ? (
                                <span>
                                    <SmokingIcon /> smoking
                                </span>) : (
                                <span>
                                    <NoSmokingIcon /> No smoking
                                </span>)}
                        </div>
                        <div>
                            {detail.features.ev ? (
                                <span>
                                    <EvIcon /> EV charger
                                </span>) : (
                                <span>
                                    <NoEvIcon /> No EV charger
                                </span>)}
                        </div>
                        <div>
                            {detail.features.wheelchair ? (
                                <span>
                                    <WheelchairIcon /> wheelchair
                                </span>) : (
                                <span>
                                    <NoWheelchairIcon /> No wheelchair
                                </span>)}
                        </div>
                    </div>
                </div>
            ),
        },

        {
            key: '9',
            label: 'Amenities',
            span: windowSize === 'small' ? 3 : 1,
            children: (
                <div className='amenities'>
                    <div>Air condition: {detail.features.ac}</div>
                    <div>Heating: {detail.features.heating}</div>
                    <div>Parking type: {detail.features.parking}</div>
                    <div>Laundry: {detail.features.laundry}</div>
                </div>
            )
        },
    ]
    return (
        <div className='metricsCard'>
            <Descriptions responsive layout="vertical" items={items} className='viewBox' bordered />
            <div className='viewButtons'>
                <div className="backButton">
                    <a href="/dashboard" className="grey-btn-link">
                        <CommonButton
                            text="Back"
                            className="grey-btn"
                        />
                    </a>
                </div>
                <div className="editButton">
                    <a href={`/dashboard/property?id=${id}`} className="grey-btn-link">
                        <CommonButton
                            text="Edit"
                            className="grey-btn"
                        />
                    </a>
                </div>
            </div>
            {showModal && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content" onClick={e => e.stopPropagation()}>
                        <span className="modal-close" onClick={closeModal}>X</span>
                        <img src={`${baseURL}${id}/photo/${currentImage}`} alt="Enlarged view" className="modal-image" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default MetricsCard;

