import React from 'react';
import styles from './Loader.module.scss';
import { Spinner } from 'react-bootstrap';

const Loader = (props) => {
  const{generate}=props
  return (
    <div className={styles.loader}>
      <div className='loader-container' style={{ "textAlign": "center"}}>
        <Spinner />
        <p className="loader-text">{generate||""}</p>
      </div>
    </div>
  );
};

export default Loader;
