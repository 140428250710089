import React from 'react';
import styles from './Input.scss';
import { ErrorMessage, Field } from 'formik';
import Errormsg from '../ErrorMsg/Errormsg';
import ReactDOM from 'react-dom'
// import { isStrictAlphanumeric } from '../../../../utils/helper';
const handleWheel = (e) => {
  console.log(e)
  e.preventDefault();
  // e.preventDefault();
};
const Input = ({ label, icon, name, type, className, formik, lefticon, lefticonshow, innerClass, righticonshow, righticon, ...rest }) => {


  setTimeout(() => {
    let input = document.querySelector('#' + name)
    if (input) {
      input.addEventListener('wheel', handleWheel, { passive: false });
    }
  }, 1000);


  return (
    <div className={`input_group ${className}`}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className={`input_group_inner ${innerClass}`}>
        {lefticonshow && <span className='lefticon'>{lefticon}</span>}
        <Field id={name} {...rest} type={type || 'text'} name={name} />
        {righticonshow && <span className='righticon'>{righticon}</span>}
      </div>
      <ErrorMessage name={name}>
        {(msg) => {
          return <Errormsg>{msg}</Errormsg>;
        }}
      </ErrorMessage>
    </div>
  );
};

export default Input;
