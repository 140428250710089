import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useNavigate, useSearchParams, useBlocker } from "react-router-dom";
import { http } from "../../../utils/http";
import CommonHeading from "../../common/CommonHeading/CommonHeading";
import Loader from "../../common/Loader/Loader";
import AddStep1 from "./AddStep1/AddStep1";
import AddStep2, { DROPDOWN_VALUES } from "./AddStep2/AddStep2";
import AddStep3 from "./AddStep3/AddStep3";
import AddStep4 from "./AddStep4/AddStep4";
import "./Property.scss";
import PropertySteps from "./PropertySteps/PropertySteps";
import _ from "lodash";
import CustomModal from "../../common/CustomModal/CustomModal";
import PropertyDetails from "../PropertyDetails/PropertyDetails";

const Property = () => {
	const navigate = useNavigate();
	const formRef = useRef();
	const [currentStep, setCurrentStep] = useState(1);
	const [add, setAdd] = useState(null);
	const [loading, setLoading] = useState(false);
	const [storeDetail, setStoreDetail] = useState(null);
	const [previewImages, setPreviewImage] = useState(null);
	const [social, setSocial] = useState(null);
	const [propertyId, setPropertyId] = useState();
	const [patchState, setPatchState] = useState(false);
	const [isChange, setIsChange] = useState(false);
	const [buttonClicked, setButtonClicked] = useState('');
	var storeForm = {};
	const [detail, setDetail] = useState({
		type: "",
		address: {
			line1: "",
			line2: "",
			city: "",
			province: "",
			country: "",
			zip: "",
		},
		bedroom: "",
		bathroom: "",
		sqft: "",
		rent: "",
		availability: "",
		features: {
			cat: false,
			dog: false,
			furnished: false,
			smoking: false,
			ac: DROPDOWN_VALUES.AC_TYPE,
			wheelchair: false,
			heating: DROPDOWN_VALUES.HEATING_TYPE,
			ev: false,
			laundry: DROPDOWN_VALUES.LAUNDARY_TYPE,
			parking: DROPDOWN_VALUES.PARKING_TYPE,
			others: [],
			highlight: "",
			amenities: "",
		},
		title: "",
		description: "",
		prompt: "formal",
		photos: [],
		platforms: {
			facebook: false,
			craigslist: false,
			zumper: false,
		},
	});

	const [show, setShow] = useState(false);
	const [isBlocker, setIsBlocker] = useState(true);

	const [searchParams] = useSearchParams();
	const id = searchParams.get("id");
	const type = searchParams.get("type");

	const blocker = useBlocker((tx) => {
		if (!formRef.current) return;
		const formData = new FormData(formRef.current.getElementsByTagName('form')[0]);
		let obj = {};
		for (const [name, value] of formData.entries()) {
			obj[name] = value;
		}
		let isValue = isBlocker && (!_.isEqual(storeForm, obj)) || isChange;
		setShow(isValue);
		return isValue;
	})
	const handleCancel = () => {
		setShow(false);
		blocker.proceed();
	};

	const handleClose = () => {
		setShow(false);
	};



	const handleSave = async () => {
		setShow(false);
		// 用来触发提交按钮
		if (formRef.current) {
			setButtonClicked('save');
			const form = formRef.current.getElementsByTagName('form')[0];
			form.querySelector('#addStepSubmit').click();
		};
	}





	useEffect(() => {
		const fetchDataAndHandleSubmit = async () => {
			if (!blocker || !blocker.proceed) return;

			// await handleSubmit();
			blocker.proceed();
		};

		fetchDataAndHandleSubmit();
	}, [detail])

	const handleSubmit = () => {
		// if ((!previewImages) || (previewImages && previewImages.length === 0)) {
		// 	toast.error("please select at least one image !!");
		// } else if (social.facebook || social.craigslist || social.zumper) {
		addProperty();
		// } else {
		// 	toast.error("please select at least one Post option !!");
		// }
	};

	useEffect(() => {
		console.log('detail :>> ', detail);
	}, [detail]);

	useEffect(() => {
		if (id) {
			setLoading(true);
			propertyDetail(id);
		}
	}, []);

	const currenSteps = (key) => {
		switch (key) {
			case 1:
				return (
					<AddStep1
						setCurrentStep={setCurrentStep}
						setDetail={setDetail}
						detail={detail}
						setAdd={setAdd}
						add={add}
						setPatchState={setPatchState}
						setIsBlocker={setIsBlocker}
						buttonClicked={buttonClicked}
					/>
				);
			case 2:
				return (
					<AddStep2
						setCurrentStep={setCurrentStep}
						setDetail={setDetail}
						detail={detail}
						addProperty={addProperty}
						propertyId={id}
						setPatchState={setPatchState}
						setIsChange={setIsChange}
					/>
				);
			case 3:
				return (
					<AddStep3
						setCurrentStep={setCurrentStep}
						setDetail={setDetail}
						detail={detail}
						patchState={patchState}
						setIsChange={setIsChange}
						buttonClicked={buttonClicked}
					/>
				);
			case 4:
				return (
					<AddStep4
						setCurrentStep={setCurrentStep}
						setDetail={setDetail}
						detail={detail}
						addProperty={addProperty}
						previewImages={previewImages}
						setPreviewImage={setPreviewImage}
						social={social}
						setSocial={setSocial}
						setIsBlocker={setIsBlocker}
						setIsChange={setIsChange}
						blocker={blocker}
						buttonClicked={buttonClicked}
					/>
				);
			default:
				break;
		}
	};
	// useEffect(() => {
	// 	if (!formRef || !formRef.current) return;
	// 	const formData = new FormData(formRef.current.getElementsByTagName('form')[0]);
	// 	let obj = {};
	// 	for (const [name, value] of formData.entries()) {
	// 		obj[name] = value;
	// 	}
	// 	storeForm = _.cloneDeep(obj);
	// }, [currenSteps])

	useEffect(() => {
		if (!formRef || !formRef.current) return;
		const formData = new FormData(formRef.current.getElementsByTagName('form')[0]);
		let obj = {};
		for (const [name, value] of formData.entries()) {
			obj[name] = value;
		}
		storeForm = _.cloneDeep(obj);
	}, [currenSteps])
	const propertyDetail = (id) => {
		try {
			http.get(`/property/${id}`).then((res) => {
				setLoading(false);
				if (res?.status === 200) {
					setDetail(res?.data);
					setStoreDetail(_.cloneDeep(res?.data));
					setPreviewImage(res?.data.photos);
					setSocial(res?.data?.platforms);
					setAdd({
						label: res?.data?.address?.line1,
						value: res?.data?.address?.address_line1,
					});
				}
				if (type === 'draft') {
					setCurrentStep(2);
					currenSteps(2);
				}
			});
		} catch (error) {
			setLoading(false);
			console.error(error);
		}
	};

	const addProperty = () => {
		setLoading(true);
		try {
			if (id) {
				http.patch(`/property/${id}`, {
					...detail,
					features: {
						...detail.features,
						amenities: JSON.stringify(JSON.parse(detail.features.amenities).filter(item => item.time && item.destination && item.method)),
					}
				}).then((res) => {
					setLoading(false);
					if (res?.data?.message === "success") {
						toast.success("updated successfully !!");
						navigate("/dashboard");
					}
				});
			} else {
				http.post("/property", detail).then((res) => {
					setLoading(false);
					if (res?.data?.message === "success") {
						toast.success("added successfully !!");
						navigate("/dashboard");
					}
				});
			}
		} catch (error) {
			setLoading(false);
			console.error(error);
		}
	};

	useEffect(() => {
		if (type === "draft") {
			setCurrentStep(2);
		}
	}, [type]);

	return (
		<>
			{loading ? (
				<Loader />
			) : (
				<div className="propertyPage">
					<Container>
						<CommonHeading heading={id ? "Edit Property" : "Add Property"} />
						<div className="CommanstepCard mt-4">
							{
								<CustomModal
									show={show}
									handleCancel={handleCancel}
									handleClose={handleClose}
									handleSave={handleSave}
									message={"Changes you made may not be saved."}
									saveButton={"Save"}
								/>
							}
							<div ref={formRef}>{currenSteps(currentStep)}</div>
							<PropertySteps currentStep={currentStep} />
						</div>
					</Container>
				</div>
			)}
		</>
	);
};

export default Property;

