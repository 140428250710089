import { Toaster } from 'react-hot-toast';
import Application from './Application';
import React, { createContext, useState } from 'react';
export const UserContext = createContext();

function App() {
  const [user, setUser] = useState({
    // Initial user profile data including image URL
    imageUrl: null,
  });

  const updateUserProfile = (newUserData) => {
    setUser((prevUser) => ({
      ...prevUser,
      ...newUserData,
    }));
  };

  return (
    <>
      <UserContext.Provider value={{ user, updateUserProfile }}>
        <Application />
        <Toaster
          toastOptions={{ className: 'custom-toast' }}
          position="top-center"
          reverseOrder={false}
        />
      </UserContext.Provider>
    </>
  );
}

export default App;
