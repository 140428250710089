import React, { useEffect, useState } from "react";
import "./AddStep2.scss";
import { Col, Form, Row } from "react-bootstrap";
import CheckBox from "../../../common/formik/CheckBox/CheckBox";
import CommonButton from "../../../common/CommonButton/CommonButton";
import { windowSize } from "../../../../utils/helper"
import { AddIcon, CarIcon, DeleteIcon, WalkIcon } from "../../../../assets/svgs/svg";
import Errormsg from "../../../common/formik/ErrorMsg/Errormsg";
import { http } from "../../../../utils/http";
import toast from "react-hot-toast";
import { useLocation, useSearchParams } from "react-router-dom";
import _ from 'lodash';

export const DROPDOWN_VALUES = {
  LAUNDARY_TYPE: "Laundry type",
  HEATING_TYPE: "Heating type",
  AC_TYPE: "Air Conditioning type",
  PARKING_TYPE: "Parking type",
}



const AddStep2 = (props) => {
  const { setCurrentStep, setDetail, detail, propertyId, setPatchState } = props;
  const [selected, setSelected] = useState(detail?.features?.others || null);
  const [isOther, setIsOther] = useState(
    detail?.features?.others?.length ? true : false
  );
  const [selectedItem, setSelectedItem] = useState('');
  const [storeDetail, setStoreDetail] = useState({});
  useEffect(() => {
    setStoreDetail(_.cloneDeep(detail))
  }, [])

  const [features, setFeatures] = useState(detail?.features);
  const [nearbys, setNearbys] = useState(
    detail?.features && detail?.features.amenities && (JSON.parse(detail?.features.amenities).length > 0) //old condition || detail?.features.amenities !== ""
      ? JSON.parse(detail?.features.amenities)
      : [
        {
          time: '',
          method: '',
          destination: '',
        },
        {
          time: '',
          method: '',
          destination: '',
        },
        {
          time: '',
          method: '',
          destination: '',
        },
      ]
  );
  console.log("step2: ", propertyId)

  const [placeholder1, setPlaceholder1]
    = useState([
      {
        id: 1,
        placeholder: "school"
      },
      {
        id: 1,
        placeholder: "subway station"
      },
      {
        id: 1,
        placeholder: "downtown"
      },
    ]);

  const [placeholder2, setPlaceholder2] = useState([
    {
      id: 1,
      placeholder: 10
    },
    {
      id: 1,
      placeholder: 5
    },
    {
      id: 1,
      placeholder: 15
    },
  ]);

  const handleIconClick = (method, index) => {
    const updatedNearbys = nearbys.map((nearby, key) => {
      if (index === key) {
        return {
          ...nearby,
          method: method,
        };
      }
      return nearby;
    });
    setNearbys(updatedNearbys);
  };

  const [errors, setErrors] = useState({});


  const handleSubmit = async () => {

    // validation for nearby if one field is filled and another is not
    if (nearbys.filter(item => {
      return (item.destination && !item.time) || (item.time && !item.destination)
    }).length > 0) {
      return setErrors(prev => ({ ...prev, nearBy: "Everything is required" }))
    }

    // validation for nearby if inputs are filled but method is not selected
    if (
      nearbys.filter(item => {
        return (item.destination && item.time)
      }).length > 0 &&
      !(nearbys.filter(item => {
        return item.method
      }).length > 0)) {
      return setErrors(prev => ({ ...prev, nearBy: "Method is required" }))
    }

    //validation for laundry type if not selected 
    if (!([
      "in-unit",
      "in building",
      "available",
      "n/a",
    ].filter(item => item === features?.laundry).length > 0)) {
      return setErrors(prev => ({ ...prev, laundry: "Laundry type is required" }))
    }

    if (!([
      "central",
      "electric",
      "gas",
      "radiator",
      "available",
      "n/a",
    ].filter(item => item === features.heating).length > 0)) {
      return setErrors(prev => ({ ...prev, heating: "Heating type is required" }))
    }

    if (!([
      "central",
      "available",
      "n/a",
    ].filter(item => item === features.ac).length > 0)) {
      return setErrors(prev => ({ ...prev, ac: "Air Conditioning type is required" }))
    }

    if (!([
      "central",
      "street",
      "off-street",
      "available",
      "n/a",
    ].filter(item => item === features.parking).length > 0)) {
      return setErrors(prev => ({ ...prev, parking: "Parking type is required" }))
    }



    const updatedFeatures = _.cloneDeep({

      ...features,
      amenities: JSON.stringify(nearbys),
    });

    // Use an intermediate variable to swap the values
    let tempHighlight = detail?.features?.highlight;

    if (features?.highlight !== undefined) {
      detail.features.highlight = features.highlight;
    }

    if (tempHighlight !== undefined) {
      updatedFeatures.highlight = tempHighlight;
    }

    const updatedDetail = {
      ...detail,
      features: updatedFeatures,
    };



    console.log(`Step 2 Property ID: ${propertyId}`);
    console.log(`Detail features:`, storeDetail.features)
    console.log(`Updated Detail features:`, updatedDetail.features);
    console.log(updatedDetail.features === storeDetail.features)

    //sent patch request 
    if (!_.isEqual(updatedDetail.features, storeDetail.features)) {
      try {
        await http.patch(`/property/${propertyId}`, updatedDetail).then((res) => {
          if (res?.status === 200) {
            setDetail(updatedDetail);
            setPatchState(true);
            // toast.error("Failed to update property.");
            setCurrentStep(3);
          } else {
            toast.error("Failed to update property.");
          }
        });
      } catch (error) {
        console.error("Error updating property:", error);
        toast.error("An error occurred while updating the property.");
      }
    } else {
      console.log('No changes detected, no update required.');
      setCurrentStep(3);
    }
  };

  const onChangeHandler = (e) => {
    props.setIsChange(true);
    const { id } = e.target;
    if (e.target.checked) {
      if (id === "others") {
        setIsOther(true);
      }
      setFeatures({ ...features, [id]: true });
    } else {
      if (id === "others") {
        setIsOther(false);
      }
      setFeatures({ ...features, [id]: false });
    }
  };

  const nearByAddHandler = () => {
    setPlaceholder1([...placeholder1, {
      id: 1,
      placeholder: "Downtown"
    }])
    setPlaceholder2([...placeholder2, {
      id: 1,
      placeholder: 15
    }]);
    setNearbys([...nearbys, { time: null, key: nearbys.length + 1, destination: null }]);
    // console.log("placeholder1", "placeholder2") 
    console.log("placeholder1", placeholder2);
  };

  const nearByDeleteHandler = (id) => {
    const updatedNearbys = nearbys.filter((item, index) => {
      return index !== id;
    });
    // updatedNearbys.splice(id, 1);
    setNearbys(updatedNearbys);
    setDetail((prev) => ({
      ...prev,
      features: {
        ...prev.features,
        amenities: JSON.stringify(updatedNearbys),
      },
    }));
  };

  return (
    <>
      <div className="propertyDetails">
        <p className="propertyHeading">Please select all that applies</p>
        <form>
          <Row>
            <Col xs={6} md={6}>
              <CheckBox
                label="Cat"
                onChange={onChangeHandler}
                id="cat"
                name="cat"
                checked={features && features.cat}
              />
              <CheckBox
                label="Dog"
                onChange={onChangeHandler}
                id="dog"
                name="dog"
                checked={features && features.dog}
              />
            </Col>

            <Col xs={6} md={6}>
              <CheckBox
                onChange={onChangeHandler}
                id="furnished"
                name="furnished"
                checked={features && features.furnished}
                label="Furnished"
              />
              {/* <CheckBox
                label="Dogs"
                onChange={onChangeHandler}
                id="dog"
                checked={features &&  features.dog}
              /> */}
              <CheckBox
                onChange={onChangeHandler}
                id="ev"
                name="ev"
                checked={features && features.ev}
                label="EV Charging"
              />
            </Col>
            <Col xs={6} md={6}>

              <CheckBox
                onChange={onChangeHandler}
                id="smoking"
                name="smoking"
                checked={features && features.smoking}
                label="Smoking"
              />
            </Col>

            <Col xs={6} md={6}>
              <CheckBox
                onChange={onChangeHandler}
                id="wheelchair"
                name="wheelchair"
                checked={features && features.wheelchair}
                label="Wheelchair"
              />
            </Col>
            {/* <Col xs={12} md={6}>
              <CheckBox
                onChange={onChangeHandler}
                id="others"
                checked={isOther}
                label="Others"
              />
            </Col>
            <div className="input-tag">
              {isOther && (
                <>
                  <label>
                    Please add all other details you would like potential
                    tenants to know
                  </label>
                  <TagsInput
                    value={selected}
                    onChange={(e) => {
                      setSelected(e);
                      setFeatures({ ...features, ["others"]: e });
                    }}
                    name="fruits"
                    placeHolder="eg:- private swimming pool"
                  />
                </>
              )}
            </div> */}
            <Col xs={12} md={6}>
              <Col xs={12} md={6}>
                <select
                  className="form-select"
                  value={features?.laundry}
                  name="laundry"
                  onChange={(e) => {
                    setFeatures({
                      ...features,
                      ["laundry"]: e.target.value,
                    });
                    props.setIsChange(true);
                    setErrors(prev => ({
                      ...prev,
                      laundry: "",
                    }))
                  }
                  }
                >
                  {/* <option value="false">Laundry type</option> */}
                  <option value={DROPDOWN_VALUES.LAUNDARY_TYPE} selected>{DROPDOWN_VALUES.LAUNDARY_TYPE}</option>
                  <option value="in-unit">In Unit</option>
                  <option value="in building">In Building</option>
                  <option value="available">Laundry Available</option>
                  <option value="n/a">No Laundry </option>
                </select>
                {
                  errors.laundry &&
                  <Errormsg>
                    {errors.laundry}
                  </Errormsg>
                }
              </Col>
            </Col>
            <Col xs={12} md={6}>
              <Col xs={12} md={6}>
                <select
                  className="form-select"
                  value={features?.heating}
                  name="heating"
                  onChange={(e) => {
                    setFeatures({
                      ...features,
                      ["heating"]: e.target.value,
                    });
                    props.setIsChange(true);
                    setErrors(prev => ({
                      ...prev,
                      heating: "",
                    }))
                  }
                  }
                >
                  <option value={DROPDOWN_VALUES.HEATING_TYPE} selected>{DROPDOWN_VALUES.HEATING_TYPE}</option>
                  {/* <option value="false">Heating type</option> */}
                  <option value="central">Central</option>
                  <option value="electric">Electric</option>
                  <option value="gas">Gas</option>
                  <option value="radiator">Radiator</option>
                  <option value="available">Heating Available </option>
                  <option value="n/a">N/A </option>
                </select>
                {
                  errors.heating &&
                  <Errormsg>
                    {errors.heating}
                  </Errormsg>
                }
              </Col>
            </Col>
            <Col xs={12} md={6}>
              <Col xs={12} md={6}>
                <select
                  className="form-select"
                  value={features?.ac}
                  name="ac"
                  onChange={(e) => {
                    setFeatures({ ...features, ["ac"]: e.target.value });
                    props.setIsChange(true);
                    setErrors(prev => ({
                      ...prev,
                      ac: "",
                    }))
                  }
                  }
                >
                  <option value={DROPDOWN_VALUES.AC_TYPE} selected>{DROPDOWN_VALUES.AC_TYPE}</option>
                  {/* <option value="false">Air Conditioning type</option> */}
                  <option value="central">Central AC</option>
                  <option value="available">AC Available</option>
                  <option value="n/a">N/A </option>
                </select>
                {
                  errors.ac &&
                  <Errormsg>
                    {errors.ac}
                  </Errormsg>
                }
              </Col>
            </Col>
            <Col xs={12} md={6}>
              <Col xs={12} md={6}>
                <select
                  className="form-select"
                  value={features?.parking}
                  name="parking"
                  onChange={(e) => {
                    props.setIsChange(true);
                    setFeatures({
                      ...features,
                      ["parking"]: e.target.value,
                    });
                    setErrors(prev => ({
                      ...prev,
                      parking: "",
                    }))
                  }
                  }
                >
                  <option value={DROPDOWN_VALUES.PARKING_TYPE} selected>{DROPDOWN_VALUES.PARKING_TYPE}</option>
                  {/* <option value="false">Parking type</option> */}
                  <option value="central">Garage</option>
                  <option value="street">Street</option>
                  <option value="off-street"> Off-Street </option>
                  <option value="available"> Parking Available </option>
                  <option value="n/a">N/A </option>
                </select>
                {
                  errors.parking &&
                  <Errormsg>
                    {errors.parking}
                  </Errormsg>
                }
              </Col>
            </Col>

          </Row>

          {windowSize !== 'small' &&
            <>
              <Row>
                <Col xs={12} md={6} className="mt-2">
                  <label htmlFor="" className="form-label">
                    Please add nearby information
                  </label>
                  {nearbys.map((item, index) => {
                    return (
                      <React.Fragment key={item.key}>
                        <div className="nearby mb-2">
                          <Form.Group>
                            <Form.Control
                              placeholder={placeholder2[index]?.placeholder}
                              type="number"
                              value={item.time}
                              name={"placeholder" + index}
                              onChange={(e) => {
                                let updatedNearbys = nearbys.map((nearby, ind) => {
                                  if (index === ind) {
                                    return {
                                      ...nearby,
                                      time: e.target.value,
                                    };
                                  }
                                  return nearby;
                                });
                                props.setIsChange(true);
                                setNearbys(updatedNearbys);
                                setDetail((prev) => {
                                  return {
                                    ...prev,
                                    features: {
                                      ...prev.features,
                                      amenities: JSON.stringify(updatedNearbys),
                                    },
                                  };
                                });
                                setErrors(prev => ({
                                  ...prev,
                                  nearBy: "",
                                }))
                              }}
                            />
                          </Form.Group>
                          <span className="textMin">
                            mins
                          </span>
                          <span
                            className={`nearby-icons ${nearbys[index].method === 'car' ? 'selected-type' : ''}`}
                            onClick={(e) => {
                              handleIconClick('car', index);
                              setErrors(prev => ({
                                ...prev,
                                nearBy: "",
                              }))
                            }
                            }
                          >
                            <CarIcon />
                          </span>&nbsp;&nbsp;
                          <span
                            className={`nearby-icons ${nearbys[index].method === 'walk' ? 'selected-type' : ''}`}
                            onClick={(e) => {
                              handleIconClick('walk', index);
                              setErrors(prev => ({
                                ...prev,
                                nearBy: "",
                              }))
                            }
                            }
                          >
                            <WalkIcon />
                          </span>
                          <span className="textMin">
                            to
                          </span>
                          <Form.Group>
                            <Form.Control
                              placeholder={placeholder1[index]?.placeholder}
                              type="text"
                              value={item.destination}
                              name="destination"
                              onChange={(e) => {
                                let updatedNearbys = nearbys.map((nearby, ind) => {
                                  if (index === ind) {
                                    return {
                                      ...nearby,
                                      destination: e.target.value,
                                    };
                                  }
                                  return nearby;
                                });
                                props.setIsChange(true);
                                setNearbys(updatedNearbys);
                                setDetail((prev) => {
                                  return {
                                    ...prev,
                                    features: {
                                      ...prev.features,
                                      amenities: JSON.stringify(updatedNearbys),
                                    },
                                  };
                                });
                                setErrors(prev => ({
                                  ...prev,
                                  nearBy: "",
                                }))
                              }}
                            />
                          </Form.Group>
                          <div onClick={nearByAddHandler} className='addIcon'>
                            <AddIcon />
                          </div>
                          {
                            nearbys.length === 1 &&
                            (
                              item.destination || item.time || item.method
                            ) &&
                            <div className='deleteIcon' onClick={() => {
                              setNearbys([
                                {
                                  time: '',
                                  method: '',
                                  destination: '',
                                },
                              ]);
                              setDetail((prev) => {
                                return {
                                  ...prev,
                                  features: {
                                    ...prev.features,
                                    amenities: JSON.stringify([
                                      {
                                        time: '',
                                        method: '',
                                        destination: '',
                                      },
                                    ]),
                                  },
                                };
                              });
                            }}>
                              Clear
                            </div>
                          }
                          {
                            nearbys.length > 1 &&
                            <div className='deleteIcon' onClick={() => nearByDeleteHandler(index)}>
                              <DeleteIcon />
                            </div>
                          }
                        </div>
                      </React.Fragment>
                    );
                  })}

                  {
                    errors.nearBy &&
                    <Errormsg>
                      {errors.nearBy}
                    </Errormsg>
                  }
                </Col>
                <Col xs={12} md={6}>
                  <div className="mb-3">
                    <label for="" className="form-label">
                      Please add highlights of your property
                    </label>
                    <textarea
                      className="form-control"
                      //   name="title"
                      id=""
                      rows="12"
                      value={detail.features && detail.features.highlight}
                      name="highlight"
                      onChange={(e) => {
                        props.setIsChange(true);
                        setDetail({
                          ...detail,
                          features: {
                            ...detail.features,
                            highlight: e.target.value,
                          },
                        });
                      }}
                    ></textarea>
                  </div>
                </Col>
              </Row>
            </>
            ||
            <Row>
              <Col xs={12} md={6}>
                <div className="mb-3">
                  <label for="" className="form-label">
                    Please add highlights of your property
                  </label>
                  <textarea
                    className="form-control"
                    //   name="title"
                    id=""
                    rows="12"
                    value={detail.features.highlight}
                    name="highlight"
                    onChange={(e) => {
                      props.setIsChange(true);
                      setDetail({
                        ...detail,
                        features: {
                          ...detail.features,
                          highlight: e.target.value,
                        },
                      });
                    }}
                  ></textarea>
                </div>
              </Col>
              <Col xs={12} md={6} className="mt-2">
                <label htmlFor="" className="form-label">
                  Please add nearby information
                </label>
                {nearbys.map((item, index) => {
                  return (
                    <div key={index} className="nearby mb-2">
                      <Form.Group>
                        <Form.Control
                          placeholder={placeholder2[index]?.placeholder}
                          type="number"
                          value={item.time}
                          name={"nearbys" + index}
                          onChange={(e) => {
                            let updatedNearbys = nearbys.map((nearby, ind) => {
                              if (index == ind) {
                                return {
                                  ...nearby,
                                  time: e.target.value,
                                };
                              }
                              return nearby;
                            });
                            props.setIsChange(true);
                            setNearbys(updatedNearbys);
                            setDetail((prev) => {
                              return {
                                ...prev,
                                features: {
                                  ...prev.features,
                                  amenities: JSON.stringify(updatedNearbys),
                                },
                              };
                            });
                          }}
                        />
                      </Form.Group>
                      <span className="textMin">
                        mins to
                      </span>
                      <Form.Group>
                        <Form.Control
                          placeholder={placeholder1[index]?.placeholder}
                          type="text"
                          value={item.destination}
                          name={'destination' + index}
                          onChange={(e) => {
                            let updatedNearbys = nearbys.map((nearby, ind) => {
                              if (index === ind) {
                                return {
                                  ...nearby,
                                  destination: e.target.value,
                                };
                              }
                              return nearby;
                            });
                            props.setIsChange(true);
                            setNearbys(updatedNearbys);
                            setDetail((prev) => {
                              return {
                                ...prev,
                                features: {
                                  ...prev.features,
                                  amenities: JSON.stringify(updatedNearbys),
                                },
                              };
                            });
                          }}
                        />
                      </Form.Group>
                      <div onClick={nearByAddHandler} className='addIcon'>
                        <AddIcon />
                      </div>
                      {
                        nearbys.length > 1 &&
                        <div className='deleteIcon' onClick={() => nearByDeleteHandler(index)}>
                          <DeleteIcon />
                        </div>
                      }
                    </div>
                  );
                })}
              </Col>
            </Row>
          }

          <div className="button_margin">
            <div className="d-flex align-items-center justify-content-between mb-5">
              <CommonButton
                text="Back"
                className="grey-btn"
                onClick={(e) => {
                  setCurrentStep(1);
                }}
              />
              <CommonButton
                text="Next"
                type="button"
                id="addStepSubmit"
                onClick={(e) => {
                  handleSubmit(e);
                }}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddStep2;
