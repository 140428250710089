import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import "../AddStep4.scss";
import { appUrl, assets, propertyPath } from '../../../../../utils/config';
import ImageIcon from "../../../../../assets/images/user1.png";
import { useLocation } from 'react-router-dom';
import { PictureIcon, VideoIcon } from "../../../../../assets/svgs/svg";

const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    padding: grid * 1,
    margin: `0 ${grid}px 0 0`,
    background: isDragging ? 'lightgreen' : 'grey',
    ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    display: 'flex',
    padding: grid,
    overflow: 'auto',
});

const ImageDrag = ({ previewImages, setPreviewImage, imageDeleteHandler, detail, setDetail, handleImageChange }) => {
    const location = useLocation();
    const [propertyId, setPropertyId] = useState('');
    const [confirmIndex, setConfirmIndex] = useState(null);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const id = searchParams.get('id');
        if (id) {
            setPropertyId(id);
        }
    }, [location]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (confirmIndex !== null) {
                setConfirmIndex(null);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [confirmIndex]);

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const updatedImages = Array.from(previewImages);
        const [reorderedItem] = updatedImages.splice(result.source.index, 1);
        updatedImages.splice(result.destination.index, 0, reorderedItem);

        setPreviewImage(updatedImages);

        const updatedDetailPhotos = Array.from(previewImages);
        const [reorderedDetailItem] = updatedDetailPhotos.splice(result.source.index, 1);
        updatedDetailPhotos.splice(result.destination.index, 0, reorderedDetailItem);

        setDetail({ ...detail, ["photos"]: updatedDetailPhotos });
    };

    const handleDeleteClick = (index) => {
        if (confirmIndex === index) {
            imageDeleteHandler(index);
        } else {
            setConfirmIndex(index);
        }
    };

    const isValidImageUrl = (url) => {
        return url.indexOf('//') === -1;
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="images" direction='horizontal'>
                {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                        }}
                    >
                        {detail.photos.map((image, index) => {
                            return (
                                <Draggable
                                    key={image}
                                    draggableId={image}
                                    index={index}
                                    style={{
                                        width: '25%',
                                        minWidth: '25%',
                                        maxWidth: '25%'
                                    }}
                                >
                                    {(provided) => (
                                        <div
                                            className="uploadFile_preview mx-2 flexer"
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                            onClick={(e) => e.stopPropagation()} // Stop propagation to avoid triggering the global click handler
                                        >
                                            {propertyId ? (
                                                <img
                                                    src={
                                                        typeof image === "string" && image.length
                                                            ? `${appUrl}/${propertyPath}/${propertyId}/photo/${image}`
                                                            : typeof image === "string" && !image.length
                                                                ? ImageIcon
                                                                : URL.createObjectURL(image)
                                                    }
                                                    alt={
                                                        typeof image === "string" && image.length
                                                            ? "Property image"
                                                            : typeof image === "string" && !image.length
                                                                ? "Default image"
                                                                : "Uploaded image"
                                                    }
                                                />
                                            ) : ""}
                                            <span
                                                className={`close-icon ${confirmIndex === index ? 'confirm' : ''}`}
                                                onClick={() => handleDeleteClick(index)}

                                            >
                                                {confirmIndex === index ? 'Remove photo?  X' : '×'}
                                            </span>
                                            {index === 0 && (
                                                <p className="title-image text-center">Cover photo</p>
                                            )}
                                        </div>
                                    )}
                                </Draggable>
                            )
                        })}
                        <div className="uploadFile text-center">
                            <span className="uploadIcon">
                                <PictureIcon />
                            </span>
                            {previewImages.length >= 0 ? (
                                <p>Add more photos</p>
                            ) : (
                                <p>Upload Photo of Your Property</p>
                            )}
                            <input
                                type="file"
                                multiple
                                accept="image/*"
                                className="uploadinput"
                                onChange={(e) => {
                                    handleImageChange(e);
                                }}
                            />
                        </div>
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default ImageDrag;
