import React, { useState } from 'react'
import CommonButton from '../../common/CommonButton/CommonButton';
import { Col, Row } from 'react-bootstrap';
import OtpCode from '../../common/OtpCode/OtpCode';

const SignupOtp = () => {
    const [otp, setOtp] = useState("");
    const submitHandler = () => {
        return
    }
    return (
        <>
            <form>
                <Row>
                    <Col xs={12}>
                        <h6>We just send you a verify code. Check your inbox to get them.</h6>
                    </Col>
                    <Col xs={12} className='mt-5 mb-4'>
                        <OtpCode setOtp={setOtp} otp={otp} />
                    </Col>
                    <Col xs={12}>
                        <CommonButton
                            // isLoading={formik.isSubmitting}
                            type="submit"
                            text="Continue"
                            fluid={true}
                            disabled={otp.length < 4}
                            onClick={(e) => { submitHandler(e) }}
                        />
                    </Col>
                </Row>
                <div className='googleCaptcha mt-5'>
                    <p>This site is protected by reCAPTCHA and the Google Privacy Policy.</p>
                </div>
            </form>

        </>
    )
}

export default SignupOtp
