import React, { useContext, useEffect, useRef, useState } from "react";
import "./Header.scss";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo.svg";
import { Container, Dropdown, Nav, Navbar } from "react-bootstrap";
import userimg from "../../../assets/images/person-4.svg";
import { UserContext } from "../../../App";
import { http } from "../../../utils/http";
import dummyProfilePic from "../../../assets/images/dummyProfilePic.webp";
import { appUrl, assets, avatarPath, propertyPath, userPath } from "../../../utils/config";
import NavigationMenu from "../NavigationMenu/NavigationMenu";


const Header = ({ image,
  // onInboxClick

}) => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [list, setList] = useState([]);
  const ref = useRef(null);
  const logoutHandler = (e) => {
    e.preventDefault();
    localStorage.clear();
    navigate("/login");
  };
  const handleToggler = () => {
    document.body.clientWidth < 991 && ref.current && ref.current.click();
  }
  useEffect(() => {
    //获取 unread - 数据
    http.get("/messages").then((res) => {
      if (res?.status === 200) {
        setList(res?.data);
      }
    });
    // getUserProfile()
  }, []);

  //list 
  // console.log('length:' + list.length)

  // unread message
  const hasUnreadMessage = list.some(item => item.unread > 0)

  const getUserProfile = () => {
    http.get("/profile").then((res) => {
    });
  }


  const handleInboxClick = () => {
    console.log('Inbox clicked');
    // Your inbox click logic here
  };

  return (
    <header className="siteHeader">
      <Navbar expand="lg" className="p-0">
        <Container className="align-items-center">
          <div className="siteHeader_Logo">
            <Link to="/dashboard">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          {/* <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="">
              <NavLink
                onClick={handleToggler} to="/dashboard" end>
                {" "}
                Dashboard
              </NavLink>
              <NavLink
                to="/dashboard/property"
                onClick={() => {
                  localStorage.removeItem("generated");
                  handleToggler();
                }}
                end
              >
                New Property
              </NavLink>
              <NavLink
                onClick={handleToggler}
                to="/dashboard/chats"
                className={hasUnreadMessage ? "red-dot" : " "}
                end
              >
                Inbox
              </NavLink>
            </Nav>
          </Navbar.Collapse> */}
          <Navbar.Collapse id="basic-navbar-nav">
            <NavigationMenu handleToggler={handleToggler} hasUnreadMessage={hasUnreadMessage}
              onInboxClick={handleInboxClick}
            //  onInboxClick={onInboxClick} 

            />
          </Navbar.Collapse>
          <div className="headerRight d-flex align-items-center">
            <div className="ProfileDropdown">
              <Dropdown>
                <Dropdown.Toggle variant="" id="dropdown-basic">
                  <img
                    src={image ? `${appUrl}/${avatarPath}/${userPath}/${image}` : `${dummyProfilePic}`}
                    alt="img"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  <Dropdown.Item
                    onClick={(e) => {
                      navigate("/dashboard");
                    }}
                    to="#"
                  >
                    Dashboard
                  </Dropdown.Item>
                  <Dropdown.Item
                    to="#"
                    onClick={(e) => {
                      navigate("/dashboard/profile");
                    }}
                  >
                    Profile
                  </Dropdown.Item>
                  <Dropdown.Item
                    to="#"
                    onClick={(e) => {
                      logoutHandler(e);
                    }}
                  >
                    Log out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <Navbar.Toggle ref={ref} aria-controls="basic-navbar-nav" className="ms-4" />
          </div>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
