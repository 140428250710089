import React, { useEffect, useState } from "react";
import "./AddStep3.scss";
import { ErrorMessage, Form, Formik, useFormik } from "formik";
import { Button, Col, Modal, Row } from "react-bootstrap";
import FormikControl from "../../../common/formik/FormikControl";
import CommonButton from "../../../common/CommonButton/CommonButton";
import * as Yup from "yup";
import styles from "../../../../components/common/formik/ErrorMsg/Errormsg.module.scss";
import { http } from "../../../../utils/http";
import Loader from "../../../common/Loader/Loader";
import { useSearchParams } from "react-router-dom";
import { RefreshIcon } from "../../../../assets/svgs/svg";
import _, { isEmpty } from "lodash";

const AddStep3 = (props) => {
    const { setCurrentStep, setDetail, detail, patchState, buttonClicked } = props;
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const [clicked, setClicked] = useState(false);
    const [modalRefresh, setModalRefresh] = useState(false);
    const [loading, setLoading] = useState(
        localStorage.getItem("generated") || id ? false : true
    );
    const [prompt, setPrompt] = useState("");
    const [modal, setModal] = useState(false);

    const [generate, setGenerate] = useState(
        "Our AI is generating the description, might take up to 1 minute, don’t worry your internet is still working!"
    );

    const [button, setButton] = useState(detail?.prompt);
    const [selectedTone, setSelectedTone] = useState(detail?.prompt);
    const initialValues = {
        title: detail?.title,
        description: detail?.description,
        prompt: detail?.prompt,
    }

    // const [generatedDetails, setGeneratedDetails] = useState({
    //     Formal: localStorage.getItem("Formal"),
    //     Casual: localStorage.getItem("Casual"),
    //     Custom: localStorage.getItem("Custom"),
    // });

    const formik = useFormik({
        initialValues: {
            title: detail?.title,
            description: detail?.description,
            prompt: detail?.prompt,
        },
        validationSchema: Yup.object({
            title: Yup.string().required("This field is required"),
            description: Yup.string().required("This field is required"),
        }),
        onSubmit: values => {
            const updatedDetail = {
                ...detail,
                title: values.title,
                description: values.description,
                prompt: values.prompt,
            };
            // setClicked(true);
            let body = {
                title: values?.title,
                description: values?.description,
                prompt: values.prompt,
            };

            localStorage.setItem("generated", true);

            //decide whether to patch or post
            if (!_.isEqual(initialValues, values)) {
                updateDescription(updatedDetail)
            } else {
                console.log("initialValues: ", initialValues)
                console.log("values: ", values)
                setCurrentStep(2);
                console.log("No changes detected, no update required. ")
            }

            console.log("step3 id: " + id)
            setCurrentStep(4);
        }
    });

    const updateDescription = (updatedDetail) => {
        try {
            http.patch(`/property/${id}`, updatedDetail).then((res) => {
                if (res?.status === 200) {
                    setDetail(updatedDetail)
                    console.log("Description updated successfully");
                } else {
                    console.error("Failed to update description");
                }
            });
        } catch (error) {
            console.error("Error updating description:", error);
        }
    };

    // console.log('formik :>> ', formik.values);
    // useEffect(() => {
    //     if (!generatedDetails[selectedTone]) {
    //         if (!detail?.title || !detail?.description) {
    //             getGeneratedDetail(selectedTone);

    //         } else {
    //             setFormikDefaultValues();
    //         }
    //     } else {
    //         let details = JSON.parse(generatedDetails[selectedTone]);
    //         console.log("🚀 ~ useEffect ~ generatedDetails:", generatedDetails)
    //         console.log("🚀 ~ useEffect ~ details:", details)
    //         setDetail(JSON.parse(generatedDetails[selectedTone]));
    //     }
    // }, [selectedTone, generatedDetails]);

    // const setFormikDefaultValues = () => {
    //     formik.setValues({
    //         title: detail?.title,
    //         description: detail?.description,
    //     });
    // };

    const getGeneratedDetail = async (tone, options) => {
        const exisitingDetails = JSON.parse(localStorage.getItem("details"))
        // console.log('exisitingDetails :>> ', exisitingDetails[tone]);
        if (exisitingDetails[tone] && !options?.refresh) {
            formik.setFieldValue("description", exisitingDetails[tone]);
            return;
        } else {
            try {
                let body = {
                    prompt: formik.values.prompt,
                    details: {
                        address: detail?.address,
                        bedroom: detail?.bedroom,
                        bathroom: detail?.bathroom,
                        sqft: detail?.sqft,
                        rent: detail?.rent,
                        availability: detail?.availability,
                        features: detail?.features,
                    },
                };
                setLoading(true);
                http.post("/property/generate", body).then((res) => {
                    setLoading(false);
                    if (res?.status === 200) {
                        if (res?.data?.error) {
                            console.error(res.data.error);
                        } else {
                            formik.setFieldValue("title", res.data.title)
                            formik.setFieldValue("description", res.data.description)
                            localStorage.setItem("details", JSON.stringify({
                                ...exisitingDetails,
                                [tone]: res.data.description,
                            }))
                            console.log('exisitingDetails :>> ', exisitingDetails);
                            console.log("LOCALSTORAGE", localStorage.getItem("details"))
                            // setDetail(JSON.parse(newDetail));
                            // setSelectedTone(tone);
                        }
                    }
                });
            } catch (error) {
                setLoading(false);
                console.error(error);
            }
        }
    };


    useEffect(() => {
        const details = {
            formal: "",
            casual: "",
            custom: "",
        }
        localStorage.setItem("details", JSON.stringify({
            ...details,
            [detail?.prompt]: detail?.description,
        }))
    }, [])

    //校验step1与step2是否发生改变，若改变则重新生成detail
    useEffect(() => {
        if ((patchState || isEmpty(initialValues.description?.trim() || '') || isEmpty(initialValues.title?.trim() || '')) && buttonClicked !== "save") {
            getGeneratedDetail("formal")
        }
    }, []);

    // const [searchParams] = useSearchParams();
    // const id = searchParams.get("id");
    const type = searchParams.get("type");
    useEffect(() => {
        if (type === "draft" && buttonClicked !== "save") {
            getGeneratedDetail("formal")
            console.log('type :>> ', type);
        }
    }, [])






    const handleRefresh = () => {
        if (formik.values.prompt === "custom") {
            setModal(true);
            setModalRefresh(true);
        }
        else {
            getGeneratedDetail(formik.values.prompt, {
                refresh: true,
            });
        }
    };


    return (
        <>
            {loading ? (
                <>
                    {" "}
                    <Loader generate={generate} />
                </>
            ) : (
                <div className="propertyTone">
                    {
                        // <Formik
                        //     validationSchema={validationSchema}
                        //     initialValues={initialValues}
                        //     onSubmit={handleSubmit}
                        // >
                        //     {(formik) => (
                        <form onSubmit={formik.handleSubmit}>
                            <Row>
                                <Col xs={12}>
                                    {/* <FormikControl
                                        type="text"
                                        label="Title"
                                        placeholder="Modern home with spectacular ocean views"
                                        name="title"
                                    /> */}
                                    <div className={`input_group`}>
                                        <label htmlFor={"title"}>{"Title"}</label>
                                        <div className={`input_group_inner`}>
                                            <input
                                                placeholder="Modern home with spectacular ocean views"
                                                id={"title"}
                                                type={'text'}
                                                name={"title"}
                                                value={formik.values.title}
                                                onChange={e => formik.setFieldValue("title", e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className="mb-3 position-relative">
                                        <label for="" className="form-label">
                                            Description
                                        </label>
                                        <textarea
                                            className="form-control"
                                            name="description"
                                            id=""
                                            rows="12"
                                            value={formik.values.description}
                                            onChange={e => formik.setFieldValue("description", e.target.value)}
                                        ></textarea>
                                        <div
                                            className="refresh-icon-container"
                                            onClick={() => {
                                                setButton(button);
                                                handleRefresh();
                                            }}
                                        >
                                            <RefreshIcon />
                                        </div>
                                        {clicked && !formik.values.description && (
                                            <p className={styles.error_msg}>
                                                This field is required
                                            </p>
                                        )}
                                    </div>
                                </Col>
                                {/* <Col xs={12}>
                                        <div className="mb-3">
                                            <label for="" className="form-label">Tone</label>
                                            <div className='d-flex'>
                                                <CommonButton text="Formal" className={button == "formal" ? "" : "grey-btn"} onClick={() => setButton("formal")} />
                                                <CommonButton text="Casual" className={`mx-4 ${button == "casual" ? "" : "grey-btn"}`} onClick={() => { setButton("casual"); getGeneratedDetail(); setGenerate("Our AI is generating the description, might take up to 1 minute, don’t worry your internet is still working!"); setLoading(true) }} />
                                                <CommonButton text="Custom" className={button == "custom" ? "" : "grey-btn"} onClick={() => { setButton("custom"); setModal(!modal) }} />
                                            </div>
                                        </div>
                                    </Col> */}
                                <div className="d-flex">
                                    <CommonButton
                                        text="Formal"
                                        className={formik.values.prompt === "formal" ? "" : "grey-btn"}
                                        // onClick={() => handleToneChange("formal", formik)}
                                        onClick={() => { formik.setFieldValue("prompt", "formal"); getGeneratedDetail("formal"); }}
                                    />
                                    <CommonButton
                                        text="Casual"
                                        className={`mx-4 ${formik.values.prompt === "casual" ? "" : "grey-btn"}`}
                                        // onClick={() => handleToneChange("casual", formik)}
                                        onClick={() => { formik.setFieldValue("prompt", "casual"); getGeneratedDetail("casual"); }}
                                    />
                                    <CommonButton
                                        text="Custom"
                                        className={formik.values.prompt === "custom" ? "" : "grey-btn"}
                                        onClick={() => {
                                            const exisitingDetails = JSON.parse(localStorage.getItem("details"))
                                            if (exisitingDetails["custom"]) {
                                                formik.setFieldValue("description", exisitingDetails["custom"]);
                                                formik.setFieldValue("prompt", "custom")
                                                return;
                                            }
                                            if (formik.values.prompt !== "custom") {
                                                // console.log('exisitingDetails :>> ', exisitingDetails[tone]);
                                                setModal(!modal);
                                            }
                                        }}
                                    />
                                </div>
                            </Row>
                            <div className="button_margin">
                                <div className="d-flex align-items-center justify-content-between mb-5">
                                    <CommonButton
                                        text="Back"
                                        className="grey-btn"
                                        onClick={() => {
                                            setCurrentStep(2);
                                        }}
                                    />
                                    <CommonButton
                                        text="Next"
                                        type="submit"
                                        id="addStepSubmit"
                                        onClick={() => {
                                            setClicked(true);
                                        }}
                                    />
                                </div>
                            </div>
                        </form>
                        //     )}
                        // </Formik>
                    }
                </div>
            )}
            {/* modal section */}
            <Modal show={modal} onHide={() => setModal(!modal)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 style={{ color: "black" }}>
                            Propose changes for description regeneration
                        </h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <textarea
                        className="form-control"
                        placeholder="eg:-I want to shorten the description"
                        autoFocus
                        onChange={(e) => {
                            setPrompt(e.target.value);
                        }}
                    />
                </Modal.Body>
                {clicked && !prompt && (
                    <p className={styles.error_msg}>This field is required</p>
                )}
                <Modal.Footer>
                    <div className="d-flex">
                        <CommonButton
                            text="Close"
                            type="button"
                            className="grey-btn mx-4"
                            onClick={() => {
                                setModal(false);
                            }}
                        />
                        <CommonButton
                            text="Generate"
                            type="button"

                            onClick={() => {
                                setClicked(true);
                                if (prompt) {
                                    // console.log('prompt :>> ', prompt);
                                    prompt.length > 0 && setModal(false);
                                    getGeneratedDetail("custom", { refresh: modalRefresh });
                                    // setSelectedTone("Custom");
                                    formik.setFieldValue("prompt", "custom")
                                    setModalRefresh(false);
                                    // setLoading(true);
                                }
                            }}
                        />
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddStep3;
