import React from 'react';
import { Container } from 'react-bootstrap';
import Img from '../../../assets/images/norecord.png';
import styles from './NotRecord.module.scss';
import { Link } from 'react-router-dom';
const NotRecord = ({text}) => {

  return (
    <>
      <Container className="my-auto">
        <div className={styles.no_record}>
          <img src={Img} alt="" />
          <br/>
          <Link to="/dashboard/property" onClick={()=>{localStorage.removeItem("generated")}}><h3>{text||"No Record Found"}</h3></Link>
        </div>
      </Container>
    </>
  );
};

export default NotRecord;
