import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
// import './NavigationMenu.scss'; // Path to your SCSS file
import '../Header/Header.scss'

const NavigationMenu = ({ handleToggler, hasUnreadMessage, onInboxClick }) => {
  const navigate = useNavigate();

  const removeGenerated = () => {
    localStorage.removeItem("generated");
    handleToggler();
  };

  return (
    <Nav className="NavigationMenu" >
      <NavLink onClick={handleToggler} to="/dashboard" end>Dashboard</NavLink>
      <NavLink to="/dashboard/property" onClick={removeGenerated} end>New Property</NavLink>
      <NavLink onClick={handleToggler} to="/dashboard/chats" className={hasUnreadMessage ? "red-dot" : ""} end>Inbox</NavLink>
    </Nav>
  );
};

export default NavigationMenu;
