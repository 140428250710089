import React, { useContext, useEffect, useRef, useState } from "react";
import UserImg from "../../../../assets/images/user3.png";
import "./ChatsGroupList.scss";
import dummyProfilePic from "../../../../assets/images/dummyProfilePic.webp";
import {
  ArrowwhiteIcon,
  AttachmentIcon,
  CalendarchatIcon,
  SendIcon,
  ArrowDown,
  ArrowLeft
} from "../../../../assets/svgs/svg";
import Modal from "react-bootstrap/Modal";
import { Container, Dropdown, Nav, Navbar } from "react-bootstrap";
import { appUrl, assets, avatarPath, tenantPath, userPath } from "../../../../utils/config";
import { http } from "../../../../utils/http";
import { UserContext } from "../../../../App";
import Loader from "../../../common/Loader/Loader";
import dayjs from "dayjs";
import moment from 'moment-timezone';
import NavigationMenu from "../../../common/NavigationMenu/NavigationMenu"; // Ensure the path is correct
import downloadPDF from "../../../../assets/images/download-pdf.png"
import Image from 'react-bootstrap/Image';
import facebookIcon from "../../../../assets/images/fbBlue.png";
import zumperIcon from "../../../../assets/images/icons1.png";
import craigslistIcon from "../../../../assets/images/craigslist.png";


// Constants
const baseURL = 'http://assets.hexon.ai/attachment/';

const ChatsGroupList = (props) => {
  const {
    id,
    handleMenuToggle,
    isLeftSideOpen,
    setMessage,
    messageList,
    updateMessageList,
    recieverUser,
    sendMessageApi,
    message,
    selectedList,
    user,
    timezone,
    chatList,
  } = props;
  const ref = useRef();
  const chatContainerRef = useRef();
  const [show, setShow] = useState();
  const [preview, setPreview] = useState();
  const [image, setImage] = useState(null);
  const { user: User } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [previewImages, setPreviewImages] = useState([]);  // Define state for image previews // Define state for messages
  const [imageList, setImageList] = useState([]);
  // header expand && collapse 
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);


  //test 
  useEffect(() => {
    setLoading(true);
    if (messageList?.length > 0) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
    getUserDetail()

    // Print the entire message list to the console for inspection
    // console.log('messageList:', messageList);

    // Iterate over each message in the message list and log it
    // messageList.forEach((message, index) => {
    //   console.log(`Message ${index}:`, message);
    // });
    // console.log(image)
    // console.log('recieverUser ID:', recieverUser?.id);
  }, [messageList]);


  const platformIcon = (userId) => {

    const tenant = chatList.find(tenant => tenant.id === userId);
    const platform = tenant ? tenant.platform : 'can not get tenant id';
    console.log('platfrom: ' + platform)
    // console.log(type)
    switch (platform) {
      case "facebook":
        return <img src={facebookIcon} alt="icon" />;
      case "craigslist":
        return <img src={craigslistIcon} alt="icon" />;
      case "zumper":
        return <img src={zumperIcon} alt="icon" />;
      default:
        return <img src={facebookIcon} alt="icon" />;
    }
  };

  // const handleImage = (e) => {
  //   const files = e.target.files;
  //   // // 只取最新的一张
  //   // let file = files[files.length - 1];
  //   // if (file) {
  //   //   const reader = new FileReader();
  //   //   reader.onloadend = () => {
  //   //     setImageList([{ index: 0, value: reader.result, file }])
  //   //   };
  //   //   reader.readAsDataURL(file);
  //   // }
  //   // for (let i = 0; i < files.length; i++) {
  //   //   let file = files[i];
  //   //   if (file) {
  //   //     const reader = new FileReader();
  //   //     reader.onloadend = () => {
  //   //       // sendMessageApi(id, file, recieverUser.id, file.name);
  //   //       setImageList([...imageList, { index: i, value: reader.result, file }])
  //   //       console.log(imageList)
  //   //       // console.log("message " + message.attachmentType)
  //   //     };
  //   //     reader.readAsDataURL(file);
  //   //   }
  //   // }

  // };

  // const handleImage = (e) => {
  //   const files = e.target.files;
  //   const newImageList = [];
  //   for (let i = 0; i < files.length; i++) {
  //     let file = files[i];
  //     if (file) {
  //       const reader = new FileReader();
  //       reader.onloadend = () => {
  //         newImageList.push({ index: imageList.length + i, value: reader.result, file });
  //         if (newImageList.length === files.length) {
  //           setImageList((prevImageList) => [...prevImageList, ...newImageList]);
  //         }
  //       };
  //       reader.readAsDataURL(file);
  //     }
  //   }
  // };
  const handleImage = (e) => {
    const files = e.target.files;
    const newImageList = [];
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          newImageList.push({ index: imageList.length + i, value: reader.result, file });
          if (newImageList.length === files.length) {
            setImageList((prevImageList) => [...prevImageList, ...newImageList]);
            // Reset the file input so that the same file can be uploaded again
            e.target.value = null;
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const removeImage = (index) => {
    setImageList(imageList.filter((img) => img.index !== index));
  };

  // 图片加载的时候触发，该方法有缺陷，当图片数量太多时，将需要等待图片加载完成
  function imgDownload() {
    chatContainerRef.current.scrollTop =
      chatContainerRef.current.scrollHeight;
  }


  //sort message
  const sortedMessages = messageList.sort(
    //sort messages based on the timestamp of customer replies
    (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
  );

  const interval = 10 * 60 * 1000;

  const groupedMessages = sortedMessages.reduce((result, message) => {
    const timestamp = new Date(message.timestamp).getTime();
    const batchIndex = Math.floor(timestamp / interval);
    const batchKey = batchIndex * interval;

    if (!result[batchKey]) {
      result[batchKey] = [];
    }

    result[batchKey].push(message);

    return result;
  }, {});

  const sortedGroups = Object.keys(groupedMessages).sort((a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    //test
    return dateB - dateA;
  });

  // unread message


  function formatTime(isoString) {
    const date = new Date(isoString);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";
    if (hours === 0) {
      hours = 12;
    } else if (hours > 12) {
      hours -= 12;
    }
    return `${hours}:${minutes < 10 ? "0" : ""}${minutes} ${period}`;
  }
  function formatDate(date) {
    const time = moment();
    const now = time.tz(timezone).toDate();
    const messageDate = new Date(date);
    const isYesterday =
      messageDate.getFullYear() === now.getFullYear() &&
      messageDate.getMonth() === now.getMonth() &&
      messageDate.getDate() === now.getDate() - 1;
    const isToday =
      messageDate.getFullYear() === now.getFullYear() &&
      messageDate.getMonth() === now.getMonth() &&
      messageDate.getDate() === now.getDate();
    const isCurrentWeek =
      messageDate >
      new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay());
    if (isYesterday) {
      return "Yesterday";
    } else if (isToday) {
      return "Today";
    } else {
      const timeDifference = now.getTime() - messageDate.getTime();
      const oneWeek = 7 * 24 * 60 * 60 * 1000;
      if (timeDifference < oneWeek) {
        return messageDate.toLocaleDateString("en-US", { weekday: "long" });
      } else {
        return messageDate.toLocaleDateString("en-US", {
          day: "numeric",
          month: "long",
          year: "numeric",
        });
      }
    }
  }


  const getUserDetail = () => {
    setLoading(true);
    http.get('/profile')
      .then(response => {
        // console.log('response:', response);  // This will log the complete response object
        if (response?.status === 200) {
          // console.log('Avatar URL:', response?.data?.avatar);  // Log just the avatar part
          // console.log('Profile data:', response?.data?.profile);
          setImage(response?.data?.avatar);
        }
      })
      .catch(error => {
        console.error("Error fetching user details:", error);
      })
      .finally(() => {
        setLoading(false);  // End loading state
      });
  };





  // chat time
  function formatTime(time) {
    const formattedTime = dayjs(time, 'HH:mm').format('h:mm A')
    //for the test
    // console.log("formattedTime", formattedTime);
    return formattedTime;
  }
  function openPDF(value) {
    http.get(value, {
      responseType: 'blob', // 响应数据类型为 Blob 对象
    })
      .then(response => {
        console.log(response)
        // 创建一个新的 URL 对象，指向 Blob 对象
        const url = window.URL.createObjectURL(response.data);

        // 使用 window.open() 方法在新的标签页中打开 PDF 文件
        window.open(url);

        // 释放 URL 对象，以释放资源
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('There was a problem with the Axios request:', error);
      });
  }

  const handleKeyDown = (e, id) => {
    if (e.key === "Enter" && e.ctrlKey) {
      e.preventDefault();
      sendMessageApi(id);
    }
  };

  function timeNow() {
    let currentDate = moment.tz(timezone);
    // console.log(currentDate)
    return currentDate;
  }

  const renderMessages = (groupedMessages) => {
    const result = [];
    let dates = [];

    sortedGroups.forEach((date) => {
      date = Number(date);
      let formatedDate;
      // format 格式
      formatedDate = formatDate(date);
      const messagesInGroup = groupedMessages[date];
      const sortedMessagesInGroup = messagesInGroup.sort(
        //test
        (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
      );

      let startTime = null;
      let endTime = null;

      sortedMessagesInGroup.forEach((message) => {
        let currentTime = new Date(message.timestamp);

        let formattedTime;
        if (message.timestamp === undefined) {
          message.timestamp = timeNow();
        } else {
          formattedTime = formatTime(message.timestamp);
        }

        if (!startTime) {
          startTime = currentTime;
          endTime = new Date(startTime.getTime() + 10 * 60 * 1000);
          formattedTime = formattedTime;
        } else if (currentTime <= endTime) {
          formattedTime = formattedTime;
          formatedDate = "";
        } else {
          startTime = currentTime;
          endTime = new Date(startTime.getTime() + 10 * 60 * 1000);
          formattedTime = formattedTime;
        }
        result.push(
          <div>
            <center>
              {formatedDate && formattedTime && (
                <div className="date-time-container">
                  <strong className="date-time-text">
                    {formatedDate.replace("Today", "")}

                    {/* &nbsp; */}
                    {' ' + formattedTime}
                    {/* &nbsp; */}
                  </strong>
                </div>
              )}
            </center>
            <ul
              className={
                message.from === recieverUser?.id ||
                  message.to !== recieverUser?.id
                  ? "replyMgs"
                  : "sendMgs"
              }
            >
              <li>
                <div className="d-flex align-items-center">
                  {message.from === recieverUser?.id || message.to !== recieverUser?.id ? (
                    <>
                      <span className="UserImg d-md-inline-block d-none">
                        {/* <img src={recieverUser?.avatar || UserImg} alt="icon" /> */}
                        <img
                          src={
                            recieverUser.avatar === undefined
                              ? dummyProfilePic
                              : appUrl +
                              "/" +
                              avatarPath +
                              "/" +
                              tenantPath +
                              "/" +
                              recieverUser.avatar

                          }
                          alt="icon"
                        />

                      </span>
                      {message.content && !message.attachment ? (<h6>{message.content}</h6>) : (<></>)}
                      {message.attachment ? (
                        <h6>
                          {message.attachment.split(',').map(v => {
                            return (v.endsWith('.pdf') ? (
                              <div>
                                {/* <p>{message.attachment}</p> */}
                                <img onClick={() => {
                                  openPDF(`${baseURL}${recieverUser.id}/${v}`)
                                }} src={downloadPDF} className="download_pdf" />
                              </div>
                            ) : (
                              <div className="image_send">
                                {" "}
                                <img
                                  src={`${baseURL}${recieverUser.id}/${v}`}
                                  alt="image"
                                  onLoad={() => {
                                    imgDownload()
                                  }}
                                  onClick={() => {
                                    setShow(true);
                                    setPreview(`${baseURL}${recieverUser.id}/${v}`);
                                  }}
                                />{" "}
                              </div>
                            ))
                          })}
                          {message.content}
                        </h6>


                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <div className="message_content">
                      {message.content && !message.attachment ? (<h6>{message.content}</h6>) : (<></>)}
                      {message.attachment ? (
                        <h6>
                          {message.attachment.split(',').map(v => {
                            return (v.endsWith('.pdf') ? (
                              <div>
                                {/* <p>{message.attachment}</p> */}
                                <img onClick={() => {
                                  openPDF(`${baseURL}${recieverUser.id}/${v}`)
                                }} src={downloadPDF} className="download_pdf" />
                              </div>
                            ) : (
                              <div className="image_send">
                                {" "}
                                <img
                                  src={`${baseURL}${recieverUser.id}/${v}`}
                                  alt="image"
                                  onLoad={() => {
                                    imgDownload()
                                  }}
                                  onClick={() => {
                                    setShow(true);
                                    setPreview(`${baseURL}${recieverUser.id}/${v}`);
                                  }}
                                />{" "}
                              </div>
                            ))
                          })}
                          {message.content}
                        </h6>


                      ) : (
                        <></>
                      )}
                      <span className="UserImg d-md-inline-block d-none">
                        <img
                          src={
                            // http://assets.hexon.ai/avatar/user/651238572e9e39d475dae651.png
                            image === undefined
                              ? `${dummyProfilePic}`
                              : `${appUrl}/${avatarPath}/${userPath}/${image}`
                          }
                          alt="icon"
                        />

                      </span>
                    </div>
                  )}
                </div>

              </li>
            </ul>
          </div>
        );
      });
    });
    return result;
  };

  const truncateName = (name, maxLength = 15) => {
    if (name.length <= maxLength) {
      return name;
    }
    return name.slice(0, maxLength) + '...';
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleTagClick = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((t) => t !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const tags = ['Tag 1', 'Tag 2', 'Tag 3', 'Tag 4', 'Tag 5'];

  const handleToggler = () => {
    console.log('Toggler function called');
    // Your toggle logic here
  };


  return (
    <>
      {loading && <Loader />}
      {!isLeftSideOpen ? (
        <div className={"chat_profile siteHeader"} >
          <Navbar expand="lg" className="p-0">
            <Container>
              <div className="chat_profile_in">
                <span onClick={handleMenuToggle} className="chat_profile_in_icon">
                  <ArrowwhiteIcon />
                  <span className="chat_profile_in_platformIcon">
                    {
                      platformIcon(recieverUser.id)
                    }
                  </span>
                  <img
                    className="chat_profile_in_img"
                    src={
                      recieverUser?.avatar == undefined
                        ? dummyProfilePic
                        : appUrl +
                        "/" +
                        avatarPath +
                        "/" +
                        tenantPath +
                        "/" +
                        recieverUser?.avatar
                    }
                    alt=""
                  />
                </span>

                <h3>{truncateName(recieverUser?.fname)}</h3>
                <div className="flex-1">
                </div>
                {/* <div className="chat_profile_tag">
                  tag
                </div> */}
                <Navbar.Toggle ref={ref} aria-controls="basic-navbar-nav" className="ms-4" />
              </div>
            </Container>

            <Navbar.Collapse id="basic-navbar-nav">
              <NavigationMenu handleToggler={handleToggler} />
            </Navbar.Collapse>
          </Navbar>
        </div >


      ) : (
        <></>
      )}

      {
        selectedList != null ? (
          <>
            {/* <div className="chats_header">
              <div className="chats_header_basicInfo">
                <div>
                  <span className="platformIcon">
                    {
                      platformIcon(recieverUser?.id)
                    }
                  </span>
                  <span className="userIcon">
                    <img
                      src={
                        recieverUser.avatar === undefined
                          ? dummyProfilePic
                          : appUrl +
                          "/" +
                          avatarPath +
                          "/" +
                          tenantPath +
                          "/" +
                          recieverUser.avatar

                      }
                      alt="icon"
                    />

                  </span>
                  <h4>{recieverUser?.fname}</h4>
                </div>
              </div>
              <div className="chats_header_tag">
                <div className="chats_header_tag_function">
                  <div className="personal_detail_tags">
                    <ul>
                      <li> <span className="tag1"></span></li>
                      <li> <span className="tag1"></span></li>
                    </ul>
                    <ul>
                      <li> <span className="tag1"></span></li>
                      <li> <span className="tag1"></span></li>
                    </ul>
                    <ul>
                      <li> <span className="tag1"></span></li>
                      <li> <span className="tag1"></span></li>
                    </ul>
                    <ul>
                      <li> <span className="tag1"></span></li>
                      <li> <span className="tag1"></span></li>
                    </ul>
                  </div>
                  <div className="all_tags">
                    Tags table
                    {isExpanded && (
                      <div className="tags_options">
                        {tags.map((tag) => (
                          <div key={tag} className="tag_item" onClick={() => handleTagClick(tag)}>
                            <span>{tag}</span>
                            {selectedTags.includes(tag) && <span className="checkmark">✔</span>}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <span className="tag_edit_icon" onClick={toggleExpand}>
                    {isExpanded ? <ArrowDown /> : <ArrowLeft />}
                  </span>
                </div>
              </div>
            </div> */}
            <div className="chatsGroupList" ref={chatContainerRef}>
              {renderMessages(groupedMessages)}
            </div>
            {imageList && imageList.length ?
              (
                <div className="image_list">
                  {/* {imageList.map(v => {
                    return (<Image key={v.index} src={v.value} thumbnail />)
                  })} */}
                  {imageList.map(v => {
                    // 检查文件名是否以 ".pdf" 结尾
                    const isPdf = v.value.startsWith('data:application/pdf;base64');
                    // console.log(v.value)
                    // 根据是否是 PDF 文件来设置不同的 src
                    const imgSrc = isPdf ? downloadPDF : v.value;

                    return (
                      <div key={v.index} className="image_preview">
                        <Image src={imgSrc} thumbnail />
                        <button className="remove_image_button" onClick={() => removeImage(v.index)}>
                          &times;
                        </button>
                      </div>
                    );
                  })}
                </div>
              ) : <></>
            }
            <div className="SendmsgBox">
              <div className="sendInputbox">
                <textarea
                  rows="1" cols="30"
                  name="send"
                  className="sendmsgInput"
                  autoComplete="off"
                  value={message}
                  placeholder="Type to reply..."
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  onKeyDown={(e) => handleKeyDown(e, id)}
                />
                <div className="sendmsgIcon d-flex align-items-center">
                  { /**文件附件上传 */}
                  <input
                    type="file"
                    multiple
                    ref={ref}
                    accept="*"
                    onChange={handleImage} />

                  <span
                    className="attachmentIcon"
                    // click upload attachment button
                    onClick={() => ref?.current?.click()}
                  >
                    <AttachmentIcon />
                  </span>
                </div>
              </div>
              <div className="sendmsgbtn" onClick={() => {
                if (message || imageList.length > 0) {
                  sendMessageApi(id, imageList.map((v) => v.file), recieverUser.id);
                  setImageList([]);
                }
              }}>
                <span>
                  <SendIcon />
                </span>
              </div>
            </div>
          </>
        ) : (
          <div className="coversation">
            Please select chat to start conversation
          </div>
        )
      }

      <Modal show={show} onHide={() => setShow(!show)} size="lg" centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="imagecontent">
            {/** images format support */}
            <img src={preview} alt="File Preview" />
          </div>
        </Modal.Body>
      </Modal>
      {/* end */}
    </>
  );
};

export default ChatsGroupList;
