import React, { useState } from 'react';
import { ErrorMessage, Field } from 'formik';
import Errormsg from '../ErrorMsg/Errormsg';
import { EyeClose, EyeOpen } from '../../../../assets/svgs/svg';

const Password = ({ label, righticon, name, className, type, lefticonshow, lefticon, innerClass, righticonshow, ...rest }) => {
  const [show, setShow] = useState(false);
  return (
    <div className={`input_group ${className}`}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className={`input_group_inner ${innerClass}`}>
        {lefticonshow && <span className="lefticon">{lefticon}</span>}
        <Field
          {...rest}
          type={show ? 'text' : 'password'}
          name={name}
          className='input_group_password'
        />
        {righticonshow && <span className='righticon'>{righticon}</span>}
        <span onClick={() => setShow(!show)} className='lock_icon'>
          {show ? <EyeOpen /> : <EyeClose />}
        </span>
      </div>
      <ErrorMessage component={Errormsg} name={name} />
    </div>
  );
};

export default Password;
