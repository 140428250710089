export const isLoggedIn=()=>{
    if(localStorage.getItem("token")!=null){
        return true
    }else{
        return false
    }
}

export function isStrictAlphanumeric(e) {
    // Regular expression to match at least one letter and any number of letters and digits
    const regex = /^(?=.*[a-zA-Z])[a-zA-Z0-9]*$/;
    if(regex.test(e.target.value)){
        return
    }else{
        e.preventDefault()
    }
  }

  export const emailRegex=/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

  export function isValidEmail(value) {
    //checking if passed value has valid email syntax or not
    const regex = emailRegex;
    if(regex.test(value)){
        return true
    }else{
       return false
    }
  }

  export function truncateString(str, maxLength) {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + '...';
    }
    return str;
  }

  export function restrictNegative(e) {
     const regex = /^\d*[1-9]\d*$/;
     if(regex.test(e.target.value)){
         return
     }else{
         e.preventDefault()
     }
  }

  // property addStep2 adjust window size (app && web)
export let windowSize = 'large';
const width = window.screen.width;
if (width < 767) {
  windowSize = 'small';
} else if (width > 767 && width < 1023) {
  windowSize = 'medium';
} else if (width > 1023) {
  windowSize = 'large';
}